import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import logger from 'redux-logger';
import firebase from 'firebase';
import User from './User';
import API from './API';
import Admin from './Admin';
import Messages from './Messages';

export default class Reactions {
	private reducers: any;
	private store: Store;
	private user: any;
	private api: any;
	private admin: any;
	private messages: any;

	constructor() {
		firebase.initializeApp({
			projectId: process.env.REACT_APP_FIREBASE_projectId,
			apiKey: process.env.REACT_APP_FIREBASE_apiKey,
			authDomain: process.env.REACT_APP_FIREBASE_authDomain,
			databaseURL: process.env.REACT_APP_FIREBASE_databaseURL,
			storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
		});

		this.user = new User();
		this.api = new API();
		this.admin = new Admin();
		this.messages = new Messages();

		this.reducers = combineReducers({
			user: this.user.reducer,
			api: this.api.reducer,
			admin: this.admin.reducer,
			messages: this.messages.reducer,
		});

		this.store = createStore(this.reducers, applyMiddleware(logger));

		this.setStores(this.store);
	}

	public init = () => {
		return Promise.all([this.user.actions.init(), this.user.actions.check()]);
	};

	public getStore = () => this.store;

	private setStores = (store: Store) => {
		this.user.setStore(store);
		this.api.setStore(store);
		this.admin.setStore(store);
		this.messages.setStore(store);
	};
}
