import { Button, Card, CircularProgress, Grid, MenuItem, Snackbar, TextField, Theme, Typography, withStyles } from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

const styles = (theme: Theme): Record<string, CSSProperties> => ({
    component: {
        position: 'relative',
        padding: 20,
        marginBottom: 50,
    },
    loading: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 500,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    menu: {
        width: 200,
    },
    upload: {
        marginBottom: theme.spacing(1),
    },
    heading: {
        marginBottom: theme.spacing(3),
    },
    dropdownSelect: {
        paddingTop: 12,
        paddingBottom: 12,
    },
});

interface IProps {
    classes?: any;
    admin: any;
    actions: any;
}

interface IState {
    loading: boolean;
    showFileInput: boolean;
    status: string;
}

class DailyReport extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false,
            showFileInput: false,
            status: '',
        };
    }

    public render() {
        const {
            admin: {
                dailyReports: { uploads, current },
            },
            classes,
        } = this.props;
        const { loading, showFileInput, status } = this.state;

        return (
            <Card square={true} className={classes.component}>
                {loading && (
                    <div className={classes.loading}>
                        <CircularProgress color="primary" />
                    </div>
                )}
                <Typography variant="h6" className={classes.heading}>
                    Daily Report
                </Typography>
                <form noValidate={true}>
                    <Grid container={true} direction="column" alignItems="flex-start">
                        <Grid item={true}>
                            <Button
                                size="large"
                                variant="contained"
                                color="primary"
                                onClick={this.handleClickUpload}
                                className={classes.upload}
                            >
                                Upload Daily Report
                            </Button>
                            <Snackbar
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                open={status !== ''}
                                autoHideDuration={5000}
                                onClose={this.handleCloseSnackbar}
                                message="Note archived"
                            >
                                <div>
                                    {status !== '' && (
                                        <Alert severity={status === 'success' ? 'success' : 'error'}>
                                            {status === 'success' ? 'Uploaded daily report to website' : 'Error uploading file'}
                                        </Alert>
                                    )}
                                </div>
                            </Snackbar>
                        </Grid>
                        {showFileInput && (
                            <input type="file" id="dailyreport" style={{ display: 'none' }} onChange={this.handleChangeUpload} />
                        )}
                        <TextField
                            name="current"
                            variant="outlined"
                            select={true}
                            value={current}
                            onChange={(event) => this.handleChangeCurrent(event)}
                            label="Current Daily Report"
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            SelectProps={{
                                classes: {
                                    select: classes.dropdownSelect,
                                },
                            }}
                        >
                            {uploads.map((option: any, index: number) => (
                                <MenuItem key={index} value={option.filename}>
                                    {option.filename}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </form>
            </Card>
        );
    }

    private handleCloseSnackbar = () => {
        this.setState({ status: '' });
    };

    private handleChangeCurrent = (event: any) => {
        const {
            actions,
            admin: {
                dailyReports: { uploads },
            },
        } = this.props;
        const {
            target: { value },
        } = event;
        const report = uploads.find((upload: any) => upload.filename === value);

        if (report) {
            this.setState({ loading: true }, () => {
                actions
                    .postCurrentReport(report.url)
                    .then(() => {
                        this.setState({ loading: false });
                    })
                    .catch(() => {
                        this.setState({ loading: false });
                    });
            });
        }
    };

    private handleChangeUpload = (event: any) => {
        const { actions } = this.props;
        const {
            target: { value, files },
        } = event;
        const name = value
            .replace('/', '\\')
            .split('\\')
            .slice(-1)[0];

        if (files && files[0]) {
            this.setState({ loading: true }, () => {
                actions
                    .postDailyReport(files[0], name)
                    .then(() => {
                        this.setState({ loading: false, showFileInput: false, status: 'success' });
                    })
                    .catch(() => {
                        this.setState({ loading: false, showFileInput: false, status: 'error' });
                    });
            });
        }
    };

    private handleClickUpload = () => {
        this.setState({ showFileInput: true }, () => {
            const dailyReport = document.getElementById('dailyreport');

            if (dailyReport) {
                dailyReport.click();
            }
        });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: state.admin.actions,
        admin: state.admin.data,
    };
};

export default compose<IProps, {}>(connect(mapStoreToProps, null), withStyles(styles))(DailyReport);
