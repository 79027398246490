import React from 'react';
import {
    AppBar,
    Badge,
    Toolbar,
    IconButton,
    CircularProgress,
    Drawer,
    Grid,
    List,
    ListItem,
    ListItemText,
    Link,
    Hidden,
    Theme,
    withStyles,
    Button,
} from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import MenuIcon from '@material-ui/icons/Menu';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import Advisory from './Advisory';
import Messages from './Messages';
import Data from './Data';

const styles = (theme: Theme): Record<string, CSSProperties> => ({
    drawer: {
        width: 140,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 140,
        top: 52,
    },
    pageWrap: {
        marginTop: 52,
        overflowY: 'scroll',
        padding: theme.spacing(2),
        backgroundColor: '#fafafa',
        height: '100vh',
    },
});

interface IProps {
    classes: any;
    admin: any;
    messages: any;
}

interface IState {
    page: string;
    showDrawer: boolean;
}

class Admin extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            page: 'dashboard',
            showDrawer: false,
        };
    }

    public componentDidMount() {
        const { actions, initted } = this.props.admin;

        if (!initted) {
            actions.init();
        }
    }

    public render() {
        const { classes } = this.props;
        const { showDrawer } = this.state;
        const style = 'body { overflow-y: hidden }';

        return (
            <React.Fragment>
                <style dangerouslySetInnerHTML={{ __html: style }} />
                <Hidden smUp={true}>
                    <Drawer open={showDrawer} className={classes.drawer} variant="temporary">
                        {this.renderMenu()}
                    </Drawer>
                    <div className={classes.pageWrap}>{this.renderPage()}</div>
                    <AppBar position="fixed" color="primary">
                        <Toolbar>
                            <IconButton color="inherit" aria-label="open drawer" onClick={this.handleToggleDrawer}>
                                <MenuIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </Hidden>
                <Hidden xsDown={true}>
                    <Drawer className={classes.drawer} variant="permanent" classes={{ paper: classes.drawerPaper }}>
                        {this.renderMenu()}
                    </Drawer>
                    <div className={classes.pageWrap} style={{ marginLeft: 140 }}>
                        {this.renderPage()}
                    </div>
                </Hidden>
            </React.Fragment>
        );
    }

    private renderMenu = () => {
        const { page } = this.state;
        const { messages } = this.props;
        const messageCount = Object.keys(messages).filter((messageId) => !messages[messageId].viewed).length;

        return (
            <List>
                <Hidden smUp={true}>
                    <ListItem divider={true}>
                        <Button variant="text" href="/app" fullWidth={true}>
                            Back to App
                        </Button>
                    </ListItem>
                </Hidden>
                <ListItem button={true} selected={page === 'dashboard'} onClick={() => this.handleSelectPage('dashboard')}>
                    <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem button={true} selected={page === 'data'} onClick={() => this.handleSelectPage('data')}>
                    <ListItemText primary="Data" />
                </ListItem>
                <ListItem button={true} selected={page === 'advisory'} onClick={() => this.handleSelectPage('advisory')}>
                    <ListItemText primary="Advisory" />
                </ListItem>
                <ListItem button={true} selected={page === 'messages'} onClick={() => this.handleSelectPage('messages')}>
                    <Badge badgeContent={messageCount} color="primary">
                        <ListItemText primary="Messages" />
                    </Badge>
                </ListItem>
            </List>
        );
    };

    private renderPage = () => {
        const { initted } = this.props.admin;
        const { page } = this.state;

        if (!initted) {
            return (
                <Grid container={true} justify="center" alignContent="center" style={{ height: '100vh' }}>
                    <CircularProgress color="primary" />
                </Grid>
            );
        }

        return (
            <React.Fragment>
                {page === 'dashboard' && <Dashboard />}
                {page === 'data' && <Data />}
                {page === 'advisory' && <Advisory />}
                {page === 'messages' && <Messages />}
            </React.Fragment>
        );
    };

    private handleSelectPage = (page: string) => {
        this.setState({ page, showDrawer: false });
    };

    private handleToggleDrawer = () => {
        this.setState({ showDrawer: !this.state.showDrawer });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        admin: state.admin,
        messages: state.messages.data,
    };
};

export default compose<IProps, {}>(
    connect(
        mapStoreToProps,
        null
    ),
    withStyles(styles)
)(Admin);
