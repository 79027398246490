import React from 'react';
import {
    Button,
    Fab,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    TextField,
    Theme,
    withStyles,
    ListSubheader,
    ListItem,
    List,
} from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { CRITERIAS } from '../../../constants';
import * as Yup from 'yup';

const styles = (theme: Theme): Record<string, CSSProperties> => ({
    form: {
        width: '100%',
    },
    spacer: {
        marginLeft: theme.spacing(2),
    },
    buttonRow: {
        paddingTop: theme.spacing(3),
    },
    menu: {
        flexGrow: 1,
    },
    gridPadding: {
        paddingLeft: theme.spacing(2),
    },
    valueField: {
        marginLeft: theme.spacing(2),
        width: 100,
    },
    listGroup: {
        borderBottom: '1px solid rgba(0, 0, 0, .12)',
        backgroundColor: '#fafafa',
    },
    criteriaGroup: {
        width: '100%',
        borderStyle: 'solid',
        borderColor: 'rgba(0, 0, 0, .12)',
        borderWidth: 1,
        borderBottomWidth: 0,
        padding: 0,
        marginTop: theme.spacing(1),
    },
    removeButton: {
        backgroundColor: '#d9534f',
    },
    dropdownSelect: {
        paddingTop: 12,
        paddingBottom: 12,
    },
});

interface IProps {
    classes: any;
    actions: any;
    application?: any;
    handleClose: any;
}

class AdvisoryForm extends React.Component<IProps> {
    public render() {
        const { application, classes, handleClose } = this.props;
        const initialValues = {
            name: '',
            folder: '',
            specsheet: '',
            redCriteria: CRITERIAS[0],
            redThreshold: '',
            redCriterion: [],
            yellowCriteria: CRITERIAS[0],
            yellowThreshold: '',
            yellowCriterion: [],
            ...application,
        };

        return (
            <Formik initialValues={initialValues} onSubmit={this.handleSubmit}>
                {({ errors, values, handleChange, setFieldValue, setFieldError, isSubmitting }) => (
                    <Form className={classes.form} noValidate={true}>
                        <Grid container={true} direction="column">
                            <Grid item={true} xs={12} md={6}>
                                <TextField
                                    id="name"
                                    label="Name"
                                    value={values.name}
                                    onChange={handleChange}
                                    margin="normal"
                                    fullWidth={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item={true} xs={12} md={6}>
                                <TextField
                                    id="folder"
                                    label="Folder"
                                    value={values.folder}
                                    onChange={handleChange}
                                    margin="normal"
                                    fullWidth={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item={true} xs={12} md={6}>
                                <TextField
                                    id="specsheet"
                                    label="Spec Sheet"
                                    value={values.specsheet}
                                    onChange={handleChange}
                                    margin="normal"
                                    fullWidth={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid container={true} direction="row" item={true} xs={12} md={7} alignItems="center" style={{ marginTop: 20 }}>
                                <TextField
                                    name="redCriteria"
                                    variant="outlined"
                                    select={true}
                                    value={values.redCriteria}
                                    onChange={handleChange}
                                    label="Red Criteria"
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    SelectProps={{
                                        classes: {
                                            select: classes.dropdownSelect,
                                        },
                                    }}
                                    className={classes.menu}
                                >
                                    {CRITERIAS.map((criteria: any, index: number) => (
                                        <MenuItem key={index} value={criteria}>
                                            {criteria.text}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <TextField
                                    id="redThreshold"
                                    label="Value"
                                    value={values.redThreshold}
                                    onChange={handleChange}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment variant="outlined" position="end">
                                                {values.redCriteria.unit}
                                            </InputAdornment>
                                        ),
                                    }}
                                    className={classes.valueField}
                                    helperText={errors.redThreshold}
                                    error={!!errors.redThreshold}
                                />
                                <Fab
                                    size="small"
                                    style={{ marginLeft: 40 }}
                                    onClick={() => this.handleAddCriterion('red', values, setFieldValue, setFieldError)}
                                >
                                    <AddIcon />
                                </Fab>
                            </Grid>
                            <Grid container={true} item={true} xs={12} md={6}>
                                <List
                                    subheader={<ListSubheader className={classes.listGroup}>Selected Red Criterion</ListSubheader>}
                                    className={classes.criteriaGroup}
                                >
                                    {values.redCriterion.map((criterion: any, index: number) => {
                                        const { criteria, threshold } = criterion;

                                        return (
                                            <ListItem key={index} divider={true}>
                                                <Grid container={true} justify="space-between" alignItems="center">
                                                    <div>{`${criteria.text} ${threshold} ${criteria.unit}`}</div>
                                                    <IconButton
                                                        onClick={() => this.handleRemoveCriterion('red', index, values, setFieldValue)}
                                                    >
                                                        <ClearIcon fontSize="small" />
                                                    </IconButton>
                                                </Grid>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Grid>
                            <Grid container={true} direction="row" item={true} xs={12} md={7} alignItems="center" style={{ marginTop: 20 }}>
                                <TextField
                                    name="yellowCriteria"
                                    variant="outlined"
                                    select={true}
                                    value={values.yellowCriteria}
                                    onChange={handleChange}
                                    label="Yellow Criteria"
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    SelectProps={{
                                        classes: {
                                            select: classes.dropdownSelect,
                                        },
                                    }}
                                    className={classes.menu}
                                >
                                    {CRITERIAS.map((criteria: any, index: number) => (
                                        <MenuItem key={index} value={criteria}>
                                            {criteria.text}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    id="yellowThreshold"
                                    label="Value"
                                    value={values.yellowThreshold}
                                    onChange={handleChange}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment variant="outlined" position="end">
                                                {values.yellowCriteria.unit}
                                            </InputAdornment>
                                        ),
                                    }}
                                    className={classes.valueField}
                                    helperText={errors.yellowThreshold}
                                    error={!!errors.yellowThreshold}
                                />
                                <Fab
                                    size="small"
                                    style={{ marginLeft: 40 }}
                                    onClick={() => this.handleAddCriterion('yellow', values, setFieldValue, setFieldError)}
                                >
                                    <AddIcon />
                                </Fab>
                            </Grid>
                            <Grid container={true} item={true} xs={12} md={6}>
                                <List
                                    subheader={<ListSubheader className={classes.listGroup}>Selected Yellow Criterion</ListSubheader>}
                                    className={classes.criteriaGroup}
                                >
                                    {values.yellowCriterion.map((criterion: any, index: number) => {
                                        const { criteria, threshold } = criterion;

                                        return (
                                            <ListItem key={index} divider={true}>
                                                <Grid container={true} justify="space-between" alignItems="center">
                                                    <div>{`${criteria.text} ${threshold} ${criteria.unit}`}</div>
                                                    <IconButton
                                                        onClick={() => this.handleRemoveCriterion('yellow', index, values, setFieldValue)}
                                                    >
                                                        <ClearIcon fontSize="small" />
                                                    </IconButton>
                                                </Grid>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Grid>
                            <Grid container={true} item={true} xs={12} justify="flex-end" className={classes.buttonRow}>
                                {application && application._id && (
                                    <Button variant="contained" onClick={this.handleDelete}>
                                        Delete
                                    </Button>
                                )}
                                <Button variant="outlined" onClick={handleClose} className={classes.spacer}>
                                    Cancel
                                </Button>
                                <Button
                                    disabled={isSubmitting}
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    className={classes.spacer}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        );
    }

    private handleAddCriterion = (key: string, values: any, setFieldValue: any, setFieldError: any) => {
        const { redThreshold, redCriteria, redCriterion, yellowThreshold, yellowCriteria, yellowCriterion } = values;
        const chosenThreshold = key === 'red' ? redThreshold : yellowThreshold;
        const chosenCriteria = key === 'red' ? redCriteria : yellowCriteria;
        const chosenCriterion = key === 'red' ? redCriterion : yellowCriterion;
        const threshold = parseFloat(chosenThreshold);
        const schema = Yup.number()
            .typeError('must be a number')
            .required('is required')
            .min(chosenCriteria.min, 'must be greater than ${ min }')
            .max(chosenCriteria.max, 'must be less than ${ max }');

        schema
            .validate(threshold)
            .then(() => {
                setFieldValue(`${key}Criterion`, [...chosenCriterion, { criteria: chosenCriteria, threshold }]);
                setFieldValue(`${key}Threshold`, '');
            })
            .catch((errors: any) => {
                setFieldError(`${key}Threshold`, errors.message);
            });
    };

    private handleRemoveCriterion = (key: string, removeIndex: number, values: any, setFieldValue: any) => {
        const { redCriterion, yellowCriterion } = values;
        const chosenCriterion = key === 'red' ? redCriterion : yellowCriterion;
        const criterion = chosenCriterion.filter((_: any, index: number) => index !== removeIndex);

        setFieldValue(`${key}Criterion`, criterion);
    };

    private handleDelete = () => {
        const { actions, application } = this.props;

        if (application && application._id) {
            actions.deleteApplication(application._id);
        }
    };

    private handleSubmit = (values: any, { setSubmitting }: any) => {
        const { actions, application, handleClose } = this.props;
        const data = { ...values };

        delete data.redCriteria;
        delete data.redThreshold;
        delete data.yellowCriteria;
        delete data.yellowThreshold;
        delete data._id;

        if (application && application._id) {
            actions
                .updateApplication(application._id, data)
                .then(() => {
                    setSubmitting(false);
                })
                .catch(() => {
                    setSubmitting(false);
                });
        } else {
            actions
                .postApplication(data)
                .then(() => {
                    handleClose();
                })
                .catch(() => {
                    setSubmitting(false);
                });
        }
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: state.admin.actions,
    };
};

export default compose<IProps, { application?: any; handleClose: any }>(
    connect(
        mapStoreToProps,
        null
    ),
    withStyles(styles)
)(AdvisoryForm);
