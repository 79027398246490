import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';

interface IProps {
    user?: any;
    history: any;
}

interface IState {
    showMenu: boolean;
}

class NavBar extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            showMenu: false,
        };
    }

    public render() {
        const { user } = this.props;

        if (user) {
            return this.renderApp();
        }

        return this.renderPublic();
    }

    private renderPublic = () => {
        const { showMenu } = this.state;

        return (
            <div className="navbar navbar-default navbar-fixed-top header">
                <div className="container">
                    <div className="navbar-header">
                        <button id="navButton" type="button" className="navbar-toggle" onClick={this.toggleMenu}>
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                        </button>
                        <Link className="logo" to="/">
                            <img src="/assets/images/weatherToolsBlue.svg" alt="Weather Tools" style={{ height: 60, padding: 8 }} />
                        </Link>
                    </div>
                    <div id="navCollapse" className="navbar-collapse collapse" style={{ display: showMenu ? 'block' : 'none' }}>
                        <div className="btn-toolbar pull-right" role="toolbar">
                            <div className="btn-group" role="group">
                                <Link className="btn btn-default navbar-btn" to="/login">
                                    Login
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    private renderApp = () => {
        const {
            user,
            history: { location },
        } = this.props;
        const { pathname } = location;

        return (
            <div className="navbar navbar-default navbar-fixed-top header">
                <div className="container">
                    {user.scope === 'admin' && (
                        <ul className="nav navbar-nav navbar-left">
                            <li>
                                <Link className="navbar-logo" to="/app">
                                    <img src="/assets/images/weatherToolsBlue.svg" alt="Weather Tools" style={{ height: 60, padding: 8 }} />
                                </Link>
                            </li>
                            <li>
                                {pathname.includes('/admin') && <Link to="/app">App</Link>}
                                {pathname.includes('/app') && <Link to="/admin">Admin</Link>}
                            </li>
                            <li className="pull-right hidden-sm hidden-md hidden-lg">
                                <Link to="/app/settings">
                                    Welcome <span id="firstname">{user.firstName}</span>
                                </Link>
                            </li>
                        </ul>
                    )}
                    {user.scope !== 'admin' && (
                        <ul className="nav navbar-nav navbar-left">
                            <li>
                                <Link className="navbar-logo" to="/app">
                                    <img src="/assets/images/weatherToolsBlue.svg" alt="Weather Tools" style={{ height: 60, padding: 8 }} />
                                </Link>
                            </li>
                            <li className="pull-right hidden-sm hidden-md hidden-lg">
                                <Link to="/app/settings">
                                    Welcome <span id="firstname">{user.firstName}</span>
                                </Link>
                            </li>
                        </ul>
                    )}
                    <ul className="nav navbar-nav navbar-right hidden-xs">
                        <li>
                            <Link to="/app/settings">
                                Welcome <span id="firstname">{user.firstName}</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

    private toggleMenu = () => {
        this.setState({ showMenu: !this.state.showMenu });
    };
}

export default compose<IProps, { user?: any }>(withRouter)(NavBar);
