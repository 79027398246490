export const ALERT_TYPES = [{ text: 'Mobile', value: 'mobile' }, { text: 'Email', value: 'email' }];

export const SNOOZE_TIMES = [
	{ text: '30 min', value: 30 },
	{ text: '1 hr', value: 60 },
	{ text: '2 hr', value: 60 * 2 },
	{ text: '3 hr', value: 60 * 3 },
	{ text: '4 hr', value: 60 * 4 },
	{ text: '6 hr', value: 60 * 6 },
	{ text: '8 hr', value: 60 * 8 },
	{ text: '12 hr', value: 60 * 12 },
];

export const CRITERIAS = [
	{ label: 'Temp', text: 'temperature is less than', value: 'temperature-less', unit: 'F', min: -20, max: 120 },
	{ label: 'Temp', text: 'temperature is greater than', value: 'temperature-greater', unit: 'F', min: -20, max: 120 },
	{ label: '15min PCPN', text: '15 minute rainfall is less than', value: 'pcpn15min-less', unit: 'IN', min: 0, max: 1 },
	{ label: '15min PCPN', text: '15 minute rainfall is greater than', value: 'pcpn15min-greater', unit: 'IN', min: 0, max: 1 },
	{ label: 'PCPN / 12am', text: 'rainfall since midnight is less than', value: 'pcpnTotal-less', unit: 'IN', min: 0, max: 10 },
	{ label: 'PCPN / 12am', text: 'rainfall since midnight is greater than', value: 'pcpnTotal-greater', unit: 'IN', min: 0, max: 10 },
	{ label: 'Wind', text: '15 minute wind speed is less than', value: 'windSpeed-less', unit: 'MPH', min: 0, max: 50 },
	{ label: 'Wind', text: '15 minute wind speed is greater than', value: 'windSpeed-greater', unit: 'MPH', min: 0, max: 50 },
	{ label: 'Gust', text: '15 minute wind gust is less than', value: 'windGust-less', unit: 'MPH', min: 0, max: 85 },
	{ label: 'Gust', text: '15 minute wind gust is greater than', value: 'windGust-greater', unit: 'MPH', min: 0, max: 85 },
	{ label: 'RH', text: 'relative humidity is less than', value: 'rh-less', unit: '%', min: 0, max: 100 },
	{ label: 'RH', text: 'relative humidity is greater than', value: 'rh-greater', unit: '%', min: 0, max: 100 },
	{ label: 'Dew Point', text: 'dew point is less than', value: 'dewPoint-less', unit: 'F', min: -10, max: 90 },
	{ label: 'Dew Point', text: 'dew point is greater than', value: 'dewPoint-greater', unit: 'F', min: -10, max: 90 },
];
