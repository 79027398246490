import React from 'react';
import Tabs from '../Tabs';
import { connect } from 'react-redux';
import Observation from './Observation';

interface IProps {
    api: any;
    observations: any;
}

interface IState {
    initted: boolean;
    observations: [];
}

class Sites extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            initted: false,
            observations: [],
        };
    }

    public componentDidMount() {
        const {
            api,
            observations: { loaded },
        } = this.props;

        if (!loaded) {
            api.getObservations();
        }
    }

    public render() {
        return (
            <div className="container app">
                <Tabs tab="sites" />
                <div className="row app-view">{this.renderContent()}</div>
            </div>
        );
    }

    private renderContent = () => {
        const {
            observations: { loaded, data },
        } = this.props;

        if (!loaded) {
            return <div className="loading" />;
        }

        return (
            <div className="col-xs-12 col-md-8 col-md-offset-2">
                {data.map((observation: any, index: number) => {
                    return <Observation key={index} observation={observation} />;
                })}
            </div>
        );
    };
}

const mapStoreToProps = (state: any) => {
    return {
        api: state.api.actions,
        observations: state.api.observations,
    };
};

export default connect(
    mapStoreToProps,
    null
)(Sites);
