import React from 'react';
import {
    Button,
    Card,
    Checkbox,
    FormControlLabel,
    Grid,
    List,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    ExpansionPanelActions,
    Switch,
    Theme,
    Typography,
    withStyles,
} from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { ExpandMore } from '@material-ui/icons';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import moment from 'moment';
import { IMessages } from '../../../reactions/Messages';

const styles = (theme: Theme): Record<string, CSSProperties> => ({
    component: {
        position: 'relative',
        padding: 20,
        marginBottom: 50,
    },
    loading: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 500,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    heading: {
        marginBottom: theme.spacing(3),
    },
    subheader: {
        backgroundColor: '#fafafa',
        borderBottom: '1px solid rgba(0, 0, 0, .12)',
    },
    expansionPannel: {
        width: '100%',
    },
    listGroup: {
        borderWidth: '1px 1px 0 1px',
        borderColor: 'rgba(0, 0, 0, .12)',
        borderStyle: 'solid',
        marginBottom: 20,
    },
    listItem: {
        padding: 0,
    },
    formLabel: {
        marginRight: 8,
        fontSize: 16,
        width: 100,
    },
    dense: {
        marginTop: theme.spacing(2),
    },
});

interface IProps {
    classes?: any;
    actions: any;
    messages: any;
}

interface IState {
    showViewed: boolean;
}

class Messages extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            showViewed: false,
        };
    }

    public render() {
        const { classes } = this.props;
        const { showViewed } = this.state;

        return (
            <Card square={true} className={classes.component}>
                <Grid container={true} className={classes.heading} direction="column">
                    <Typography variant="h6">Messages</Typography>
                    <FormControlLabel
                        control={<Switch checked={showViewed} onChange={this.handleShowViewed} value="value" color="primary" />}
                        label="Show viewed messages"
                    />
                    <List className={classes.root}>{this.renderMessages()}</List>
                </Grid>
            </Card>
        );
    }

    private renderMessages = () => {
        const { messages } = this.props;
        const { showViewed } = this.state;

        return Object.keys(messages)
            .filter((messageId) => {
                const message: IMessages = messages[messageId];

                return showViewed ? true : !message.viewed;
            })
            .sort((messageIdA, messageIdB) => {
                const messageA: IMessages = messages[messageIdA];
                const messageB: IMessages = messages[messageIdB];

                return moment(messageA.createdAt).unix() > moment(messageB.createdAt).unix() ? 1 : -1;
            })
            .map((messageId: string, index: number) => {
                const message: IMessages = messages[messageId];

                return (
                    <ExpansionPanel key={index} square={true} elevation={1}>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                            <Typography>
                                <span style={{ fontWeight: 'bold', paddingRight: 4 }}>From:</span>
                                {message.name}
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container={true} direction="column">
                                <Typography style={{ marginTop: 8, marginBottom: 8 }}>
                                    <span style={{ fontWeight: 'bold', paddingRight: 4 }}>Email:</span>
                                    {message.email}
                                </Typography>
                                <Typography style={{ marginTop: 8, marginBottom: 8 }}>
                                    <span style={{ fontWeight: 'bold' }}>Message:</span>
                                </Typography>
                                <Typography style={{ marginTop: 8, marginBottom: 8 }}>{message.message}</Typography>
                                <Typography style={{ marginTop: 8, marginBottom: 8 }}>
                                    <span style={{ fontWeight: 'bold', paddingRight: 4 }}>Created:</span>
                                    {moment(message.createdAt).format('MM/DD/YYYY h:mm A')}
                                </Typography>
                            </Grid>
                        </ExpansionPanelDetails>
                        <ExpansionPanelActions>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={message.viewed}
                                        onChange={() => this.handleMarkViewed(messageId)}
                                        value="value"
                                        color="primary"
                                    />
                                }
                                label="Mark as viewed"
                                style={{ marginBottom: 0 }}
                            />
                            <Button variant="contained" color="primary" onClick={() => this.handleDelete(messageId)}>
                                Delete Message
                            </Button>
                        </ExpansionPanelActions>
                    </ExpansionPanel>
                );
            });
    };

    private handleShowViewed = () => {
        this.setState({ showViewed: !this.state.showViewed });
    };

    private handleMarkViewed = (messageId: string) => {
        const { actions, messages } = this.props;
        const message = messages[messageId];

        return actions.messages.update(messageId, { viewed: !message.viewed });
    };

    private handleDelete = (messageId: string) => {
        const { actions } = this.props;

        return actions.messages.delete(messageId);
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: {
            messages: state.messages.actions,
        },
        messages: state.messages.data,
    };
};

export default compose<IProps, {}>(
    connect(
        mapStoreToProps,
        null
    ),
    withStyles(styles)
)(Messages);
