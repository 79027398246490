import * as Yup from 'yup';

import { Form, Formik } from 'formik';

import React from 'react';
import Tabs from '../Tabs';
import { connect } from 'react-redux';

interface IProps {
    api: any;
    user: any;
    actions: any;
}

interface IState {
    showToken: boolean;
    token: string;
}

const UserSchema = Yup.object().shape({
    firstName: Yup.string().required('is required'),
    lastName: Yup.string().required('is required'),
});

const PasswordSchema = Yup.object().shape({
    password: Yup.string()
        .matches(/^.*(?=.*\d).*/i, 'at least one number')
        .min(4, 'at least four characters long'),
});

class Settings extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            showToken: false,
            token: localStorage.getItem('token') || '',
        };
    }

    public render() {
        return (
            <div className="container app">
                <Tabs tab="settings" />
                <div className="row app-view">{this.renderContent()}</div>
            </div>
        );
    }

    private renderContent = () => {
        const { user } = this.props;
        const { showToken, token } = this.state;
        const { username, firstName, lastName } = user;

        return (
            <div className="col-xs-12 col-md-8 col-md-offset-2">
                <div className="tab">
                    <div className="alerts-heading">
                        <h4>Account Details</h4>
                    </div>
                    <Formik validationSchema={UserSchema} initialValues={{ firstName, lastName }} onSubmit={this.updateUser}>
                        {({ errors, values, touched, handleChange, isSubmitting }) => (
                            <Form className="form-horizontal" noValidate={true}>
                                <div className="form-group">
                                    <label className="col-xs-12 col-sm-3 col-md-3 control-label">Username:</label>
                                    <div className="col-xs-12 col-sm-8 col-md-6">
                                        <p className="form-control-static">{username}</p>
                                    </div>
                                </div>
                                <div className={`form-group ${errors.firstName && touched.firstName ? 'has-error' : ''}`}>
                                    <label className="col-xs-12 col-sm-3 col-md-3 control-label">First Name:</label>
                                    <div className="col-xs-12 col-sm-8 col-md-6">
                                        <input
                                            id="firstName"
                                            type="text"
                                            className="form-control"
                                            placeholder="First Name"
                                            value={values.firstName}
                                            onChange={handleChange}
                                        />
                                        {errors.firstName && touched.firstName && (
                                            <span className="help-block">
                                                <em>{errors.firstName}</em>
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className={`form-group ${errors.lastName && touched.lastName ? 'has-error' : ''}`}>
                                    <label className="col-xs-12 col-sm-3 col-md-3 control-label">Last Name:</label>
                                    <div className="col-xs-12 col-sm-8 col-md-6">
                                        <input
                                            id="lastName"
                                            type="text"
                                            className="form-control"
                                            placeholder="Last Name"
                                            value={values.lastName}
                                            onChange={handleChange}
                                        />
                                        {errors.lastName && touched.lastName && (
                                            <span className="help-block">
                                                <em>{errors.lastName}</em>
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-xs-12 col-sm-11 col-md-9">
                                        <button type="submit" className="btn btn-warning pull-right" disabled={isSubmitting}>
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    <hr />
                    <div className="alerts-heading">
                        <h4>Update Password</h4>
                        <Formik validationSchema={PasswordSchema} initialValues={{ password: '' }} onSubmit={this.updatePassword}>
                            {({ errors, values, touched, handleChange, isSubmitting }) => (
                                <Form className="form-horizontal" noValidate={true}>
                                    <div className={`form-group ${errors.password && touched.password ? 'has-error' : ''}`}>
                                        <label className="col-xs-12 col-sm-3 col-md-3 control-label">Password:</label>
                                        <div className="col-xs-12 col-sm-8 col-md-6">
                                            <input
                                                id="password"
                                                type="password"
                                                className="form-control"
                                                placeholder="New Password"
                                                autoComplete="off"
                                                value={values.password}
                                                onChange={handleChange}
                                            />
                                            {errors.password && touched.password && (
                                                <span className="help-block">
                                                    <em>{errors.password}</em>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-xs-12 col-sm-11 col-md-9">
                                            <button type="submit" className="btn btn-warning pull-right" disabled={isSubmitting}>
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <hr />
                    <div className="alerts-heading" style={{ paddingBottom: 8 }}>
                        <h4>Access Token</h4>
                        <div className="input-group">
                            <span className="input-group-btn">
                                <button className="btn btn-warning" type="button" onClick={this.toggleToken}>
                                    {showToken ? 'Hide Token' : 'Reveal Token'}
                                </button>
                            </span>
                            <input id="token" type="text" className="form-control" disabled={!showToken} value={showToken ? token : ''} />
                            <span className="input-group-btn">
                                <button className="btn btn-default" type="button" onClick={this.copyToken} disabled={!showToken}>
                                    <span className="glyphicon glyphicon-copy" aria-hidden="true" />
                                </button>
                            </span>
                        </div>
                    </div>
                    <hr />
                    <div className="row" style={{ marginTop: 20 }}>
                        <div className="col-xs-12 col-sm-11 col-md-9">
                            <button className="btn btn-warning" onClick={this.logout}>
                                Logout
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    private copyToken = () => {
        const { token } = this.state;

        navigator.clipboard.writeText(token);
    };

    private toggleToken = () => {
        this.setState({
            showToken: !this.state.showToken,
        });
    };

    private updateUser = (values: any, { setSubmitting }: any) => {
        const { api } = this.props;

        api.postUserEdit(values)
            .then(() => {
                setSubmitting(false);
            })
            .catch(() => {
                setSubmitting(false);
            });
    };

    private updatePassword = (values: any, { setSubmitting, resetForm }: any) => {
        const { api } = this.props;

        api.postUserPassword(values)
            .then(() => {
                resetForm();
            })
            .catch(() => {
                setSubmitting(false);
            });
    };

    private logout = () => {
        const { actions } = this.props;

        actions.user.logout();
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: {
            user: state.user.actions,
        },
        api: state.api.actions,
        user: state.user.data,
    };
};

export default connect(
    mapStoreToProps,
    null
)(Settings);
