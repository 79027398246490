import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import queryString from 'query-string';

interface IProps {
    actions: any;
    location: any;
    user: any;
}
interface IState {
    status: string;
}

const ResetSchema = Yup.object().shape({
    password: Yup.string()
        .matches(/^.*(?=.*\d).*/i, 'at least one number')
        .min(4, 'at least four characters long'),
});

class Reset extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            status: '',
        };
    }

    public render() {
        const { status } = this.state;

        return (
            <div className="container login">
                <div className="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <h2 className="text-center">Reset Password</h2>
                            <Formik validationSchema={ResetSchema} initialValues={{ password: '' }} onSubmit={this.onSubmit}>
                                {({ errors, values, touched, handleChange }) => (
                                    <Form className="form-horizontal" noValidate={true}>
                                        {status === 'error' && (
                                            <div className="message">
                                                <div className="alert alert-danger">
                                                    <button type="button" className="close" onClick={() => this.setState({ status: '' })}>
                                                        <span>&times;</span>
                                                    </button>
                                                    <strong>Error: </strong>
                                                    <span>Unable to reset password.</span>
                                                </div>
                                            </div>
                                        )}
                                        {status === 'success' && (
                                            <div className="message">
                                                <div className="alert alert-success">
                                                    <button type="button" className="close" onClick={() => this.setState({ status: '' })}>
                                                        <span>&times;</span>
                                                    </button>
                                                    <span>Password was successfully reset.</span>
                                                </div>
                                            </div>
                                        )}
                                        <div
                                            className={`form-group has-feedback ${
                                                !!errors.password && !!touched.password ? 'has-error' : ''
                                            }`}
                                        >
                                            <div className="col-sm-8 col-sm-offset-2">
                                                <input
                                                    id="password"
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Enter new password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                />
                                                {!!errors.password && !!touched.password && (
                                                    <div>
                                                        <span className="glyphicon glyphicon-remove form-control-feedback" />
                                                        <span className="help-block">
                                                            <em>{errors.password}</em>
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="col-sm-offset-2 col-sm-8">
                                                <button type="submit" className="btn btn-block btn-lg btn-warning">
                                                    Reset Password
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private onSubmit = ({ password }: any, { setSubmitting }: any) => {
        const { actions, location, user } = this.props;
        const { access_token } = queryString.parse(location.search);

        actions.user
            .reset({ password, access_token })
            .then(async ({ status }: any) => {
                await actions.user.login({ username: user.username, password });

                this.setState({ status }, () => {
                    setSubmitting(false);
                });
            })
            .catch(() => {
                this.setState({ status: 'error' }, () => {
                    setSubmitting(false);
                });
            });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: {
            user: state.user.actions,
        },
        user: state.user,
    };
};

export default connect(mapStoreToProps, null)(Reset);
