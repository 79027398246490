import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';

interface IProps {
    actions: any;
}
interface IState {
    status: string;
}

const ForgotSchema = Yup.object().shape({
    username: Yup.string()
        .email('Invalid email')
        .required('Required'),
});

class Forgot extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            status: '',
        };
    }

    public render() {
        const { status } = this.state;

        return (
            <div className="container login">
                <div className="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <h2 className="text-center">Forgot Password?</h2>
                            <Formik validationSchema={ForgotSchema} initialValues={{ username: '' }} onSubmit={this.onSubmit}>
                                {({ errors, values, touched, handleChange, setFieldError }) => (
                                    <Form className="form-horizontal" noValidate={true}>
                                        {status === 'error' && (
                                            <div className="message">
                                                <div className="alert alert-danger">
                                                    <button type="button" className="close" onClick={() => this.setState({ status: '' })}>
                                                        <span>&times;</span>
                                                    </button>
                                                    <strong>Error: </strong>
                                                    <span>Unable to send password reset.</span>
                                                </div>
                                            </div>
                                        )}
                                        {status === 'success' && (
                                            <div className="message">
                                                <div className="alert alert-success">
                                                    <button type="button" className="close" onClick={() => this.setState({ status: '' })}>
                                                        <span>&times;</span>
                                                    </button>
                                                    <span>Password reset send, please check your email.</span>
                                                </div>
                                            </div>
                                        )}
                                        <div
                                            className={`form-group has-feedback ${
                                                !!errors.username && !!touched.username ? 'has-error' : ''
                                            }`}
                                        >
                                            <div className="col-sm-8 col-sm-offset-2">
                                                <input
                                                    id="username"
                                                    name="username"
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Enter account email"
                                                    value={values.username}
                                                    onChange={handleChange}
                                                />
                                                {!!errors.username && !!touched.username && (
                                                    <div>
                                                        <span className="glyphicon glyphicon-remove form-control-feedback" />
                                                        <span className="help-block">
                                                            <em>{errors.username}</em>
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="col-sm-offset-2 col-sm-8">
                                                <button type="submit" className="btn btn-block btn-lg btn-warning">
                                                    Send Password Reset
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private onSubmit = (values: any, { setSubmitting }: any) => {
        const { actions } = this.props;

        actions.user
            .forgot(values)
            .then(({ status }: any) => {
                this.setState({ status }, () => {
                    setSubmitting(false);
                });
            })
            .catch(() => {
                this.setState({ status: 'error' }, () => {
                    setSubmitting(false);
                });
            });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: {
            user: state.user.actions,
        },
    };
};

export default connect(
    mapStoreToProps,
    null
)(Forgot);
