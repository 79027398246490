import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import NavBar from './NavBar';
import Login from './Login';
import { connect } from 'react-redux';
import Sites from './App/Sites';
import Settings from './App/Settings';
import Advisory from './App/Advisory';
import Alerts from './App/Alerts';
import Forgot from './Forgot';
import Reset from './Reset';
import Admin from './Admin';

interface IProps {
    user?: any;
}

class Layout extends React.Component<IProps> {
    public render() {
        const { user } = this.props;

        if (user) {
            return (
                <React.Fragment>
                    <NavBar user={user} />
                    {this.renderAppRoutes()}
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <NavBar />
                {this.renderPublicRoutes()}
            </React.Fragment>
        );
    }

    private renderPublicRoutes() {
        return (
            <div style={{ backgroundColor: '#777', height: '100vh', overflowX: 'scroll', paddingTop: 51 }}>
                <Switch>
                    <Route exact={true} path="/forgot" component={Forgot} />
                    <Route exact={true} path="/reset" component={Reset} /> 
                    <Route exact={true} path="/login" component={Login} />
                    <Redirect to="/login" />
                </Switch>
            </div>
        );
    }

    private renderAppRoutes() {
        const { user } = this.props;

        return (
            <Switch>
                <Route exact={true} path="/app/sites" component={Sites} />
                <Route exact={true} path="/app/settings" component={Settings} />
                <Route exact={true} path="/app/advisory" component={Advisory} />
                <Route exact={true} path="/app/alerts" component={Alerts} />
                {user.scope === 'admin' && <Route path="/admin" component={Admin} />}
                <Redirect to="/app/sites" />
            </Switch>
        );
    }
}

const mapStoreToProps = (state: any) => {
    return {
        user: state.user.data,
    };
};

export default connect(mapStoreToProps, null)(Layout);
