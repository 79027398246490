import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CircularProgress, Drawer, Grid, List, ListItem, ListItemText, MuiThemeProvider, Theme, withStyles } from '@material-ui/core';
import Reactions from './reactions';
import Layout from './app/Layout';
import theme from './theme';

interface IState {
    initted: boolean;
}

class App extends React.Component<any, IState> {
    private reactions = new Reactions();
    private store = this.reactions.getStore();

    constructor(props: any) {
        super(props);

        this.state = {
            initted: false,
        };
    }

    public componentDidMount() {
        this.reactions.init()
            .then(() => this.setState({ initted: true }))
            .catch(() => this.setState({ initted: true }));
    }

    public render() {
        const { initted } = this.state;

        if (!initted) {
            return <div className="loading" />;
        }

        return (
            <MuiThemeProvider theme={theme}>
                <Router basename="/">
                    <Provider store={this.store}>
                        <Layout />
                    </Provider>
                </Router>
            </MuiThemeProvider>
        );
    }
}

export default App;
