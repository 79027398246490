import { createMuiTheme } from '@material-ui/core/styles';
import { lightBlue, orange, red } from '@material-ui/core/colors';

const theme = createMuiTheme({
	palette: {
		primary: { main: orange[300] },
		secondary: { main: lightBlue[300] },
		error: red,
	},
	typography: {
		fontSize: 14,
		htmlFontSize: 12,
	},
});

export default theme;
