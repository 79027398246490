import React from 'react';
import {
    Button,
    IconButton,
    ListSubheader,
    ListItem,
    List,
    Fab,
    InputAdornment,
    MenuItem,
    TextField,
    Theme,
    Grid,
    withStyles,
    Typography,
    FormHelperText,
} from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Formik, Form } from 'formik';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ALERT_TYPES, CRITERIAS } from '../../../constants';
import * as Yup from 'yup';

const styles = (theme: Theme): Record<string, CSSProperties> => ({
    form: {
        width: '100%',
    },
    spacer: {
        marginLeft: theme.spacing(2),
    },
    buttonRow: {
        paddingTop: theme.spacing(3),
    },
    selectFull: {
        flexGrow: 1,
    },
    gridPadding: {
        paddingLeft: theme.spacing(2),
    },
    dropdownField: {
        marginBottom: theme.spacing(2),
        minWidth: 140,
    },
    dropdownSelect: {
        paddingTop: 12,
        paddingBottom: 12,
    },
    textField: {
        // marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1),
        paddingTop: theme.spacing(1),
        height: 40,
    },
    label: { fontSize: 14, fontWeight: 500, paddingRight: theme.spacing(2) },
    labelWrap: {
        justifyContent: 'flex-end',
        [theme.breakpoints.only('xs')]: {
            justifyContent: 'flex-start',
        },
    },
    listGroup: {
        borderBottom: '1px solid rgba(0, 0, 0, .12)',
        backgroundColor: '#fafafa',
    },
    criteriaGroup: {
        width: '100%',
        borderStyle: 'solid',
        borderColor: 'rgba(0, 0, 0, .12)',
        borderWidth: 1,
        borderBottomWidth: 0,
        padding: 0,
        marginTop: theme.spacing(1),
    },
    removeButton: {
        backgroundColor: '#d9534f',
    },
});

const AlertSchema = Yup.object().shape({
    station: Yup.object().shape({
        name: Yup.string().required(),
        stationId: Yup.string().required(),
        _id: Yup.string().required(),
    }),
    description: Yup.string()
        .max(60)
        .required(),
    criterion: Yup.array()
        .of(
            Yup.object().shape({
                threshold: Yup.number().required('is required'),
                criteria: Yup.object().shape({
                    min: Yup.number().required(),
                    max: Yup.number().required(),
                    unit: Yup.string().required(),
                    value: Yup.string().required(),
                    text: Yup.string().required(),
                    label: Yup.string().required(),
                }),
            })
        )
        .required('at least one criteria rule is required'),
    to: Yup.object().shape({
        value: Yup.mixed().oneOf(['mobile', 'email']),
    }),
    mobile: Yup.string().when('to', {
        is: (to) => to.value === 'mobile',
        then: Yup.string()
            .length(10, 'not a valid mobile number')
            .required('is required'),
    }),
    email: Yup.string().when('to', {
        is: (to) => to.value === 'email',
        then: Yup.string()
            .email('not a valid email')
            .required('is required'),
    }),
});

interface IProps {
    classes: any;
    api: any;
    alert?: any;
    stations: any;
    onSave?: any;
    onUpdate?: any;
}

class AlertForm extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);
    }

    public render() {
        const { classes, alert, stations } = this.props;
        const initialValues: any = {
            station: alert ? alert.station : stations[0],
            description: alert ? alert.description : '',
            to: alert ? alert.to : ALERT_TYPES[0],
            email: alert ? alert.email : '',
            mobile: alert ? alert.mobile : '',
            criterion: alert ? alert.criterion : [],
            selectCriteria: CRITERIAS[0],
            selectThreshold: '',
        };

        return (
            <Formik validationSchema={AlertSchema} initialValues={initialValues} onSubmit={this.handleSubmit}>
                {({ errors, values, touched, handleChange, handleSubmit, setFieldValue, setFieldError, isSubmitting }) => (
                    <Form className={`form-horizontal ${!alert ? 'new-alert' : ''}`} noValidate={true}>
                        <Grid container={true} style={{ marginTop: 20 }} justify="flex-end">
                            {!alert && (
                                <React.Fragment>
                                    <Grid container={true} alignItems="center" item={true} xs={12} sm={2} className={classes.labelWrap}>
                                        <Typography className={classes.label}>For station:</Typography>
                                    </Grid>
                                    <Grid container={true} alignItems="center" item={true} xs={12} sm={10}>
                                        <TextField
                                            name="station.stationId"
                                            variant="outlined"
                                            select={true}
                                            value={values.station.stationId}
                                            onChange={(event) => this.handleStationChange(event, setFieldValue)}
                                            margin="normal"
                                            className={classes.dropdownField}
                                            SelectProps={{
                                                classes: {
                                                    select: classes.dropdownSelect,
                                                },
                                            }}
                                        >
                                            {stations.map((station: any, index: number) => (
                                                <MenuItem key={index} value={station.stationId}>
                                                    {station.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </React.Fragment>
                            )}
                            <Grid container={true} alignItems="center" item={true} xs={12} sm={2} className={classes.labelWrap}>
                                <Typography className={classes.label}>Description:</Typography>
                            </Grid>
                            <Grid container={true} alignItems="center" item={true} xs={12} sm={10}>
                                <TextField
                                    id="description"
                                    value={values.description}
                                    onChange={handleChange}
                                    margin="normal"
                                    className={classes.textField}
                                    helperText={errors.description}
                                    error={!!errors.description}
                                    fullWidth={true}
                                    style={{ marginLeft: 0 }}
                                />
                            </Grid>
                            <Grid container={true} alignItems="center" item={true} xs={12} sm={2} className={classes.labelWrap}>
                                <Typography className={classes.label}>Send alert to:</Typography>
                            </Grid>
                            <Grid container={true} alignItems="center" item={true} xs={12} sm={10} wrap="nowrap">
                                <TextField
                                    name="to"
                                    variant="outlined"
                                    select={true}
                                    value={values.to.value}
                                    onChange={(event) => this.handleToChange(event, setFieldValue)}
                                    margin="normal"
                                    className={classes.dropdownField}
                                    SelectProps={{
                                        classes: {
                                            select: classes.dropdownSelect,
                                        },
                                    }}
                                >
                                    {ALERT_TYPES.map((type: any, index: number) => (
                                        <MenuItem key={index} value={type.value}>
                                            {type.text}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {values.to.value === 'mobile' && (
                                    <TextField
                                        id="mobile"
                                        value={this.setMobileMask(values.mobile)}
                                        onChange={(event: any) => this.setMobileValue(event, setFieldValue)}
                                        placeholder="Mobile Number"
                                        margin="normal"
                                        fullWidth={true}
                                        className={classes.textField}
                                        helperText={errors.mobile}
                                        error={!!errors.mobile}
                                    />
                                )}
                                {values.to.value === 'email' && (
                                    <TextField
                                        id="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        placeholder="Email Address"
                                        margin="normal"
                                        fullWidth={true}
                                        className={classes.textField}
                                        helperText={errors.email}
                                        error={!!errors.email}
                                    />
                                )}
                            </Grid>
                            <Grid container={true} alignItems="center" item={true} xs={12} sm={2} className={classes.labelWrap}>
                                <Typography className={classes.label}>Send alert if:</Typography>
                            </Grid>
                            <Grid container={true} alignItems="center" item={true} xs={12} sm={10} wrap="nowrap">
                                <TextField
                                    name="selectCriteria"
                                    variant="outlined"
                                    select={true}
                                    value={values.selectCriteria}
                                    onChange={handleChange}
                                    margin="normal"
                                    className={classes.dropdownField}
                                    style={{ flexGrow: 1 }}
                                    SelectProps={{
                                        classes: {
                                            select: classes.dropdownSelect,
                                        },
                                    }}
                                    error={!!errors.criterion}
                                >
                                    {CRITERIAS.map((criteria: any, index: number) => (
                                        <MenuItem key={index} value={criteria}>
                                            {criteria.text}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    id="selectThreshold"
                                    placeholder="Value"
                                    value={values.selectThreshold}
                                    onChange={handleChange}
                                    margin="normal"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment variant="outlined" position="end">
                                                {values.selectCriteria.unit}
                                            </InputAdornment>
                                        ),
                                    }}
                                    className={classes.textField}
                                    style={{ width: 100 }}
                                    helperText={errors.selectThreshold}
                                    error={!!errors.selectThreshold}
                                />
                                <Fab
                                    color="primary"
                                    size="small"
                                    style={{ marginLeft: 40 }}
                                    onClick={() => this.handleAddCriterion(values, setFieldValue, setFieldError)}
                                >
                                    <AddIcon />
                                </Fab>
                            </Grid>
                            <Grid container={true} item={true} xs={10} style={{ marginTop: -16, marginBottom: 8 }}>
                                <FormHelperText error={!!errors.criterion}>{errors.criterion}</FormHelperText>
                            </Grid>
                            <Grid container={true} item={true} xs={12} sm={10}>
                                <List
                                    subheader={
                                        <ListSubheader className={classes.listGroup}>
                                            Selected criteria (all conditions have to be true for the alert to trigger)
                                        </ListSubheader>
                                    }
                                    className={classes.criteriaGroup}
                                >
                                    {values.criterion.map((item: any, index: number) => {
                                        const { criteria, threshold } = item;

                                        return (
                                            <ListItem key={index} divider={true}>
                                                <Grid container={true} justify="space-between" alignItems="center">
                                                    <div>{`${criteria.text} ${threshold} ${criteria.unit}`}</div>
                                                    <IconButton onClick={() => this.handleRemoveCriterion(index, values, setFieldValue)}>
                                                        <ClearIcon fontSize="small" />
                                                    </IconButton>
                                                </Grid>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Grid>
                            <Grid container={true} item={true} xs={12} justify="flex-end" style={{ paddingTop: 16 }}>
                                <Button variant="contained" size="large" color="primary" onClick={() => handleSubmit()}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        );
    }

    private handleAddCriterion = (values: any, setFieldValue: any, setFieldError: any) => {
        const { criterion, selectCriteria, selectThreshold } = values;
        const threshold = parseFloat(selectThreshold);
        const schema = Yup.number()
            .typeError('must be a number')
            .required('is required')
            .min(selectCriteria.min, 'must be greater than or equal to ${ min }')
            .max(selectCriteria.max, 'must be less than or equal to ${ max }');
        const found = criterion.find((item: any) => {
            console.info(item, selectCriteria);

            return item.criteria.value === selectCriteria.value;
        });

        if (found) {
            setFieldError('criterion', 'you can not use the same criteria rule more than once');
        } else {
            setFieldError('criterion', null);

            schema
                .validate(threshold)
                .then(() => {
                    setFieldValue('criterion', [...criterion, { criteria: selectCriteria, threshold }]);
                    setFieldValue('selectThreshold', '');
                })
                .catch((errors: any) => {
                    setFieldError('selectThreshold', errors.message);
                });
        }
    };

    private handleRemoveCriterion = (removeIndex: number, values: any, setFieldValue: any) => {
        const criterion = values.criterion.filter((_: any, index: number) => index !== removeIndex);

        setFieldValue('criterion', criterion);
    };

    private setMobileMask = (value: string) => {
        const noChars: any = value.match(/\d/g);
        let mask = '';

        if (noChars && noChars.length < 4) {
            mask = noChars.join('');
        }

        if (noChars && noChars.length >= 4 && noChars.length <= 6) {
            mask = '(' + noChars.slice(0, 3).join('') + ') ' + noChars.slice(3, 6).join('');
        }

        if (noChars && noChars.length > 6) {
            mask = '(' + noChars.slice(0, 3).join('') + ') ' + noChars.slice(3, 6).join('') + '-' + noChars.slice(6, 10).join('');
        }

        return mask;
    };

    private setMobileValue = (event: any, setFieldValue: any) => {
        const {
            target: { value },
        } = event;

        setFieldValue('mobile', value.replace(/\D/g, '').slice(0, 10));
    };

    private handleStationChange = (event: any, setFieldValue: any) => {
        const {
            target: { value },
        } = event;
        const { stations } = this.props;
        const station = stations.find((item: any) => item.stationId === value);

        setFieldValue('station', station);
    };

    private handleToChange = (event: any, setFieldValue: any) => {
        const {
            target: { value },
        } = event;
        const to = ALERT_TYPES.find((item) => item.value === value);

        setFieldValue('to', to);
    };

    private handleSubmit = (values: any, { setSubmitting }: any) => {
        const { onSave, onUpdate, alert } = this.props;
        const { to, email, mobile, criterion, station, description } = values;

        if (alert && alert._id) {
            onUpdate(alert._id, { to, email, mobile, criterion, station, description }).then(() => {
                setSubmitting(false);
            });
        } else {
            onSave({ to, email, mobile, criterion, station, description }).then(() => {
                setSubmitting(false);
            });
        }
    };
}

const mapStoreToProps = (state: any) => {
    return {
        api: state.api.actions,
    };
};

export default compose<IProps, { alert?: any; stations: any; onSave?: any; onUpdate?: any }>(
    connect(
        mapStoreToProps,
        null
    ),
    withStyles(styles)
)(AlertForm);
