import { Store, createStore } from 'redux';
import { createAction, handleActions } from 'redux-actions';
import firebase from 'firebase';
import axios from 'axios';

export interface IUserLogin {
	[key: string]: string;
	username: string;
	password: string;
}

export default class User {
	public actions = {
		init: () => new Promise((resolve) => {
				firebase.auth().onAuthStateChanged((user) => {
					const { data } = this.store.getState().user;

					if (user) {
						this.store.dispatch(
							this.setUser({
								...data,
								uid: user ? user.uid : undefined,
							})
						);
					}

					resolve();
				});
			}),
		check: () =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'get',
						url: `/check?access_token=${token}`,
					})
						.then((response) => {
							const user = firebase.auth().currentUser;

							this.store.dispatch(
								this.setUser({
									...response.data,
									uid: user ? user.uid : undefined,
								})
							);
							resolve();
						})
						.catch(() => {
							this.store.dispatch(this.setUser(null));
							firebase.auth().signOut();
							reject();
						});
				} else {
					this.store.dispatch(this.setUser(null));
					firebase.auth().signOut();
					reject();
				}
			}),
		login: (userLogin: IUserLogin) => {
			return axios({
				method: 'post',
				url: '/login',
				data: userLogin,
			})
				.then((response) => {
					const { username, password } = userLogin;
					const { token } = response.data;

					localStorage.setItem('token', token);

					return firebase
						.auth()
						.signInWithEmailAndPassword(username, password)
						.then((userCredential) => {
							this.store.dispatch(
								this.setUser({
									...response.data,
									uid: userCredential.user ? userCredential.user.uid : undefined,
								})
							);
						})
						.catch(() => {
							this.store.dispatch(this.setUser({ ...response.data, uid: undefined }));
						});
				})
				.catch((error) => {
					localStorage.clear();
					this.store.dispatch(this.setUser(null));

					return Promise.reject(error);
				});
		},
		logout: () => {
			return Promise.resolve().then(() => {
				firebase.auth().signOut();
				localStorage.clear();
				this.store.dispatch(this.setUser(null));
			});
		},
		forgot: (data: any) =>
			new Promise((resolve, reject) => {
				axios({
					method: 'post',
					url: `/forgot`,
					data,
				})
					.then((response) => resolve(response.data))
					.catch(() => reject());
			}),
		reset: (data: any) =>
			new Promise((resolve, reject) => {
				axios({
					method: 'post',
					url: `/reset`,
					data,
				})
					.then((response) => resolve(response.data))
					.catch(() => reject());
			}),
	};

	public initialState = {
		actions: this.actions,
		data: null,
	};

	public reducer = handleActions<any>(
		{
			SET_USER: (state, action) => {
				return {
					...state,
					data: action.payload,
				};
			},
		},
		this.initialState
	);

	private setUser = createAction('SET_USER');

	private store: Store = createStore(this.reducer);

	public setStore = (store: Store) => {
		this.store = store;
	};
}
