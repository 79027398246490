import React from 'react';
import { Formik, Form, FormikActions } from 'formik';
import * as Yup from 'yup';
import { IUserLogin } from '../reactions/User';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

interface IProps {
    user: any;
}
interface IState {
    loginError: boolean;
}

const LoginSchema = Yup.object().shape({
    username: Yup.string()
        .email('Invalid email')
        .required('Required'),
    password: Yup.string().required('Required'),
});

class Login extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            loginError: false,
        };
    }

    public render() {
        const { loginError } = this.state;

        return (
            <div className="container login">
                <div className="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <h2 className="text-center">Login</h2>
                            <Formik validationSchema={LoginSchema} initialValues={{ username: '', password: '' }} onSubmit={this.onSubmit}>
                                {({ errors, values, touched, handleChange, setFieldError }) => (
                                    <Form className="form-horizontal" noValidate={true}>
                                        {loginError && (
                                            <div className="message">
                                                <div className="alert alert-danger">
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        onClick={() => this.setState({ loginError: false })}
                                                    >
                                                        <span>&times;</span>
                                                    </button>
                                                    <strong>Error: </strong>
                                                    <span>Invalid Username or Password</span>
                                                </div>
                                            </div>
                                        )}
                                        <div
                                            className={`form-group has-feedback ${
                                                !!errors.username && !!touched.username ? 'has-error' : ''
                                            }`}
                                        >
                                            <div className="col-sm-offset-2 col-sm-8">
                                                <input
                                                    id="username"
                                                    name="username"
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Email"
                                                    value={values.username}
                                                    onChange={handleChange}
                                                    onFocus={() => this.clearError('username', setFieldError)}
                                                />
                                                {!!errors.username && !!touched.username && (
                                                    <div>
                                                        <span className="glyphicon glyphicon-remove form-control-feedback" />
                                                        <span className="help-block">
                                                            <em>{errors.username}</em>
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className={`form-group has-feedback ${
                                                !!errors.password && !!touched.password ? 'has-error' : ''
                                            }`}
                                        >
                                            <div className="col-sm-offset-2 col-sm-8 ">
                                                <input
                                                    id="password"
                                                    name="password"
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    onFocus={() => this.clearError('password', setFieldError)}
                                                />
                                                {!!errors.password && !!touched.password && (
                                                    <div>
                                                        <span className="glyphicon glyphicon-remove form-control-feedback" />
                                                        <span className="help-block">
                                                            <em>{errors.password}</em>
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="col-sm-offset-2 col-sm-8">
                                                <button type="submit" className="btn btn-block btn-lg btn-warning">
                                                    Login
                                                </button>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="col-sm-offset-2 col-sm-8">
                                                <Link className="btn btn-block btn-link" to="/forgot">
                                                    Forgot Password?
                                                </Link>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private clearError = (field: string, setFieldError: any) => {
        this.setState({ loginError: false }, () => {
            setFieldError(field, null);
        });
    };

    private onSubmit = (values: IUserLogin, { setSubmitting }: FormikActions<IUserLogin>) => {
        const { user } = this.props;

        user.actions
            .login(values)
            .then(() => {
                setSubmitting(false);
            })
            .catch(() => {
                this.setState({ loginError: true }, () => {
                    setSubmitting(false);
                });
            });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        user: state.user,
    };
};

export default connect(
    mapStoreToProps,
    null
)(Login);
