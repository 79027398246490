import React from 'react';
import { connect } from 'react-redux';
import Tabs from '../Tabs';
import Form from './Form';
import { SNOOZE_TIMES } from '../../../constants';
import moment from 'moment';

interface IProps {
    api: any;
}

interface IState {
    initted: boolean;
    alerts: any;
    stations: any;
    showNewAlert: boolean;
    showEditAlert: {
        [key: string]: boolean;
    };
    showSnoozeTimes: {
        [key: string]: boolean;
    };
    snooze: any;
}

class Alerts extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            initted: false,
            alerts: [],
            stations: [],
            showNewAlert: false,
            showEditAlert: {},
            showSnoozeTimes: {},
            snooze: SNOOZE_TIMES[0],
        };
    }

    public componentDidMount() {
        const { api } = this.props;

        api.getAlerts().then((alerts: any) => {
            api.getStations().then((stations: any) => {
                this.setState({ alerts, stations, initted: true });
            });
        });
    }

    public render() {
        return (
            <div className="container app">
                <Tabs tab="alerts" />
                <div className="row app-view">{this.renderContent()}</div>
            </div>
        );
    }

    private renderContent = () => {
        const { alerts, initted, stations, showNewAlert } = this.state;
        const triggered = alerts.filter((alert: any) => alert.triggered);

        if (!initted) {
            return <div className="loading" />;
        }

        return (
            <div className="col-xs-12 col-md-8 col-md-offset-2">
                <div className="tab">
                    {triggered && triggered.length > 0 && (
                        <div>
                            <div className="alerts-heading">
                                <h4>Current Triggered Alerts</h4>
                            </div>
                            <ul className="list-unstyled alerts">{this.renderTriggeredAlerts()}</ul>
                        </div>
                    )}
                    <div className="alerts-heading">
                        <h4 className="show-inline-block">{showNewAlert ? 'Create New Alert' : 'Defined Alerts'}</h4>
                        <div className="btn-group pull-right">
                            <button type="button" className="btn btn-sm btn-warning" onClick={this.toggleNewAlert}>
                                {!showNewAlert && (
                                    <div>
                                        Create New Alert <span className="glyphicon glyphicon-chevron-down" />
                                    </div>
                                )}
                                {showNewAlert && (
                                    <div>
                                        Cancel <span className="glyphicon glyphicon-chevron-up" />
                                    </div>
                                )}
                            </button>
                        </div>
                    </div>
                    {showNewAlert && <Form stations={stations} onSave={this.onSave} />}
                    {!showNewAlert && <ul className="list-unstyled alerts">{this.renderAlerts()}</ul>}
                </div>
            </div>
        );
    };

    private renderTriggeredAlerts = () => {
        const { alerts, snooze, showSnoozeTimes } = this.state;
        const triggered = alerts.filter((alert: any) => alert.triggered);

        return triggered.map((alert: any, index: number) => {
            const alertId = alert._id;

            return (
                <li key={index} className="alert-item">
                    <div className="title">
                        <span className="station">{alert.station.name}: </span>
                        <span className="text">
                            {alert.criteria && `${alert.criteria.text} ${alert.threshold} ${alert.criteria.unit}`}
                            {alert.description && `${alert.description}`}
                        </span>
                    </div>
                    {!alert.snooze && (
                        <div className="form-group" style={{ marginBottom: 0 }}>
                            <label className="control-label" style={{ marginRight: 4 }}>
                                Snooze for:
                            </label>
                            <div className="btn-group">
                                <button
                                    type="button"
                                    className="btn btn-warning btn-sm"
                                    onClick={() => this.updateSnoozeTime(snooze, alert)}
                                >
                                    {snooze.text}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-warning btn-sm dropdown-toggle"
                                    onClick={() => this.toggleSnoozeTimes(alertId)}
                                >
                                    <span className="caret" />
                                </button>
                                <ul className={showSnoozeTimes[alertId] ? 'dropdown-menu show-block' : 'dropdown-menu'}>
                                    {this.renderSnoozeTimes(alert)}
                                </ul>
                            </div>
                        </div>
                    )}
                    {alert.snooze && (
                        <div className="form-group" style={{ marginBottom: 0 }}>
                            <label className="control-label" style={{ marginRight: 4 }}>
                                Snoozed until: {moment(alert.snooze).format('ddd MM/DD hh:mm A')}
                            </label>
                            <button type="button" className="btn btn-sm btn-warning" onClick={() => this.updateSnoozeTime(null, alert)}>
                                Cancel Snooze
                            </button>
                        </div>
                    )}
                </li>
            );
        });
    };

    private renderSnoozeTimes = (alert: any) => {
        return SNOOZE_TIMES.map((snooze: any, index: number) => {
            return (
                <li key={index} className="snooze-popup" onClick={() => this.updateSnoozeTime(snooze, alert)}>
                    {snooze.text}
                </li>
            );
        });
    };

    private renderAlerts = () => {
        const { alerts, stations, showEditAlert } = this.state;

        return alerts.map((alert: any, index: number) => {
            const alertId = alert._id;

            return (
                <li key={index} className="alert-item">
                    <div className="title">
                        <span className="station">{alert.station.name}: </span>
                        <span className="text">
                            {alert.criteria && `${alert.criteria.text} ${alert.threshold} ${alert.criteria.unit}`}
                            {alert.description && `${alert.description}`}
                        </span>
                    </div>
                    <div className="btn-group pull-left" style={{ minWidth: 90 }}>
                        <button
                            type="button"
                            className={`btn btn-sm btn-default ${alert.active ? 'btn-success active' : ''}`}
                            onClick={() => this.updateActiveStatus(alert)}
                        >
                            On
                        </button>
                        <button
                            type="button"
                            className={`btn btn-sm btn-default ${!alert.active ? 'btn-danger active' : ''}`}
                            onClick={() => this.updateActiveStatus(alert)}
                        >
                            Off
                        </button>
                    </div>
                    <div className="btn-group pull-right">
                        <button
                            type="button"
                            className="btn btn-sm btn-danger"
                            disabled={showEditAlert[alertId]}
                            onClick={() => this.onDelete(alert)}
                        >
                            Delete
                        </button>
                        <button type="button" className="btn btn-default btn-sm" onClick={() => this.toggleEditAlert(alertId)}>
                            {!showEditAlert[alertId] && (
                                <div>
                                    Edit <span className="glyphicon glyphicon-chevron-down" />
                                </div>
                            )}
                            {showEditAlert[alertId] && (
                                <div>
                                    Cancel <span className="glyphicon glyphicon-chevron-up" />
                                </div>
                            )}
                        </button>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            {showEditAlert[alertId] && <Form alert={alert} stations={stations} onUpdate={this.onUpdate} />}
                        </div>
                    </div>
                </li>
            );
        });
    };

    private toggleNewAlert = () => {
        this.setState({ showNewAlert: !this.state.showNewAlert });
    };

    private toggleEditAlert = (alertId: string) => {
        this.setState({
            showEditAlert: {
                [alertId]: this.state.showEditAlert[alertId] ? !this.state.showEditAlert[alertId] : true,
            },
        });
    };

    private toggleSnoozeTimes = (alertId: string) => {
        this.setState({
            showSnoozeTimes: {
                [alertId]: this.state.showSnoozeTimes[alertId] ? !this.state.showSnoozeTimes[alertId] : true,
            },
        });
    };

    private updateSnoozeTime = (snooze: any, alert: any) => {
        const { api } = this.props;
        const alertId = alert._id;
        let time: any = null;

        if (snooze && snooze.value) {
            time = moment()
                .add(snooze.value, 'minutes')
                .toISOString();
        }

        api.postAlertSnooze(alertId, { snooze: time }).then((data: any) => {
            const alerts = this.state.alerts.map((item: any) => {
                return item._id === data._id ? data : item;
            });

            this.setState({ alerts, snooze: !time ? SNOOZE_TIMES[0] : snooze, showSnoozeTimes: {} });
        });
    };

    private updateActiveStatus = (alert: any) => {
        const { api } = this.props;
        const alertId = alert._id;

        api.postAlertActive(alertId, {
            active: !alert.active,
            triggered: alert.active ? false : alert.triggered,
        }).then((data: any) => {
            const alerts = this.state.alerts.map((item: any) => {
                return item._id === data._id ? data : item;
            });

            this.setState({ alerts });
        });
    };

    private onDelete = (alert: any) => {
        const { api } = this.props;
        const alertId = alert._id;

        api.deleteAlert(alertId).then(() => {
            return api.getAlerts().then((alerts: any) => {
                this.setState({ alerts });
            });
        });
    };

    private onUpdate = (alertId: string, values: any) => {
        const { api } = this.props;

        return api.updateAlert(alertId, values).then((alert: any) => {
            const alerts = this.state.alerts.map((item: any) => {
                return item._id === alert._id ? alert : item;
            });

            this.setState({
                alerts,
                showEditAlert: { [alertId]: false },
            });
        });
    };

    private onSave = (values: any) => {
        const { api } = this.props;

        return api.postAlert(values).then((alert: any) => {
            this.setState({
                alerts: [...this.state.alerts, alert],
                showNewAlert: false,
            });
        });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        api: state.api.actions,
    };
};

export default connect(
    mapStoreToProps,
    null
)(Alerts);
