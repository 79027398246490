import { Store, createStore } from 'redux';
import { createAction, handleActions } from 'redux-actions';
import axios from 'axios';
import moment from 'moment-timezone';

export default class API {
	public actions = {
		getSummary: () =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({ method: 'get', url: `/api/summary?access_token=${token}` })
						.then((response) => resolve(response.data))
						.catch(() => reject());
				} else {
					reject();
				}
			}),
		getObservations: () =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'get',
						url: `/api/observations?access_token=${token}&tz=${moment.tz.guess()}`,
					})
						.then(({ data }) => {
							this.store.dispatch(this.setObservations(data));
							resolve();
						})
						.catch(() => reject());
				} else {
					reject();
				}
			}),
		getForecastDays: (observationId: string) =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'get',
						url: `/api/forecast/days/${observationId}?access_token=${token}&tz=${moment.tz.guess()}`,
					})
						.then((response) => resolve(response.data))
						.catch(() => reject());
				} else {
					reject();
				}
			}),
		getForecastHours: (observationId: string, searchDate: string) =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'get',
						url: `/api/forecast/hours/${observationId}/${searchDate}?access_token=${token}&tz=${moment.tz.guess()}`,
					})
						.then((response) => resolve(response.data))
						.catch(() => reject());
				} else {
					reject();
				}
			}),
		getAlerts: () =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'get',
						url: `/api/alerts?access_token=${token}&tz=${moment.tz.guess()}`,
					})
						.then((response) => resolve(response.data))
						.catch(() => reject());
				} else {
					reject();
				}
			}),
		getStations: () =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'get',
						url: `/api/stations?access_token=${token}`,
					})
						.then((response) => resolve(response.data))
						.catch(() => reject());
				} else {
					reject();
				}
			}),
		postAlertSnooze: (alertId: string, data: any) =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'post',
						url: `/api/alert/${alertId}/snooze?access_token=${token}`,
						data,
					})
						.then((response) => resolve(response.data))
						.catch(() => reject());
				} else {
					reject();
				}
			}),
		postAlertActive: (alertId: string, data: any) =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'post',
						url: `/api/alert/${alertId}/active?access_token=${token}`,
						data,
					})
						.then((response) => resolve(response.data))
						.catch(() => reject());
				} else {
					reject();
				}
			}),
		postAlert: (data: any) =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'post',
						url: `/api/alert?access_token=${token}`,
						data,
					})
						.then((response) => resolve(response.data))
						.catch(() => reject());
				} else {
					reject();
				}
			}),
		updateAlert: (alertId: string, data: any) =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'post',
						url: `/api/alert/${alertId}?access_token=${token}`,
						data,
					})
						.then((response) => resolve(response.data))
						.catch(() => reject());
				} else {
					reject();
				}
			}),
		deleteAlert: (alertId: string) =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'delete',
						url: `/api/alert/${alertId}?access_token=${token}`,
					})
						.then((response) => resolve(response.data))
						.catch(() => reject());
				} else {
					reject();
				}
			}),
		getApplications: () =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'get',
						url: `/api/applications?access_token=${token}`,
					})
						.then((response) => resolve(response.data))
						.catch(() => reject());
				} else {
					reject();
				}
			}),
		getAdvisories: (advisoryId: string) =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'get',
						url: `/api/advisories/${advisoryId}?access_token=${token}`,
					})
						.then((response) => resolve(response.data))
						.catch(() => reject());
				} else {
					reject();
				}
			}),
		getAdvisorySnapshots: () =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'get',
						url: `/api/advisory/snapshots?access_token=${token}`,
					})
						.then((response) => resolve(response.data))
						.catch(() => reject());
				} else {
					reject();
				}
			}),
		deleteAdvisorySnapshot: (snapshotId: string) =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'delete',
						url: `/api/advisory/snapshot/${snapshotId}?access_token=${token}`,
					})
						.then((response) => resolve(response.data))
						.catch(() => reject());
				} else {
					reject();
				}
			}),
		postAdvisorySnapshot: (data: any) =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'post',
						url: `/api/advisory/snapshot?access_token=${token}`,
						data,
					})
						.then((response) => resolve(response.data))
						.catch(() => reject());
				} else {
					reject();
				}
			}),
		getUser: () =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'get',
						url: `/api/user?access_token=${token}`,
					})
						.then((response) => resolve(response.data))
						.catch(() => reject());
				} else {
					reject();
				}
			}),
		postUserEdit: ({ firstName, lastName }: any) =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'post',
						url: `/api/user?access_token=${token}`,
						data: { firstName, lastName },
					})
						.then((response) => {
							this.store.dispatch(this.setUser(response.data));
							resolve();
						})
						.catch(() => reject());
				} else {
					reject();
				}
			}),
		postUserPassword: (data: any) =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'post',
						url: `/api/user/password?access_token=${token}`,
						data,
					})
						.then((response: any) => {
							localStorage.setItem('token', response.data.token);
							resolve();
						})
						.catch(() => reject());
				} else {
					reject();
				}
			}),
	};

	public initialState = {
		actions: this.actions,
		observations: {
			data: [],
			loaded: false,
		},
	};

	public reducer = handleActions<any>(
		{
			SET_OBSERVATIONS: (state, action) => {
				return {
					...state,
					observations: {
						data: action.payload,
						loaded: true,
					},
				};
			},
		},
		this.initialState
	);

	private setObservations = createAction('SET_OBSERVATIONS');
	private setUser = createAction('SET_USER');

	private store: Store = createStore(this.reducer);

	public setStore = (store: Store) => {
		this.store = store;
	};
}
