import React from 'react';
import { connect } from 'react-redux';

interface IProps {
    api?: any;
    observation: any;
}

interface IState {
    initted: boolean;
    forecastDays: any;
    currentForecastDay: any;
    currentForecastHours: any;
    showHours: boolean;
    hoursPopup: boolean;
    hoursFilter: number;
    hoursText: string;
    showMore: any;
}

class Forecast extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            initted: false,
            forecastDays: [],
            currentForecastDay: null,
            currentForecastHours: [],
            showHours: false,
            hoursPopup: false,
            hoursFilter: 1,
            hoursText: 'Every Hour',
            showMore: {},
        };
    }

    public componentDidMount() {
        const { api, observation } = this.props;

        api.getForecastDays(observation.station._id).then((forecastDays: any) => {
            const { searchDate } = forecastDays[0];

            return api.getForecastHours(observation.station._id, searchDate).then((currentForecastHours: any) => {
                this.setState({ currentForecastHours, forecastDays, currentForecastDay: forecastDays[0], initted: true });
            });
        });
    }

    public render() {
        const { currentForecastDay, forecastDays, initted, hoursPopup, hoursText, showHours } = this.state;

        if (!initted) {
            return (
                <div className="section forecast">
                    <div className="loading" />
                </div>
            );
        }

        return (
            <div className="section forecast">
                <div className="forecast-days">
                    <ul className="list-unstyled list-inline">
                        {forecastDays.map((forecastDay: any, index: number) => {
                            return (
                                <li key={index} className={forecastDay._id === currentForecastDay._id ? 'active' : ''}>
                                    <a
                                        className={`forecast-days-title`}
                                        onClick={() => this.setCurrentForecastDay(forecastDay)}
                                    >
                                        <div className="text-center bold">{forecastDay.date}</div>
                                        <div className="text-center">
                                            <span className="text-danger">{forecastDay.maxTemp}&#176;</span> |{' '}
                                            <span className="text-info">{forecastDay.minTemp}&#176;</span>
                                        </div>
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                {currentForecastDay && (
                    <div className="forecast-day">
                        <ul className="list-unstyled">
                            <li>
                                <ul className="list-unstyled data-table">
                                    <li className="summary">
                                        <span>{currentForecastDay.summaryText}</span>
                                    </li>
                                    <li>
                                        <span className="data-label">Max Temp:</span>
                                        <span className="data-value">{currentForecastDay.maxTemp}&#176;</span>
                                    </li>
                                    <li>
                                        <span className="data-label">Min Temp:</span>
                                        <span className="data-value">{currentForecastDay.minTemp}&#176;</span>
                                    </li>
                                    <li>
                                        <span className="data-label">Wind:</span>
                                        <span className="data-value">
                                            {currentForecastDay.windDir} {currentForecastDay.windSpeed} MPH
                                        </span>
                                        <div className="btn-group pull-right forecast-hours-more-button">
                                            <button type="button" className="btn btn-warning btn-sm" onClick={() => this.showHours(true)}>
                                                Display {hoursText}
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-warning btn-sm dropdown-toggle"
                                                onClick={this.toggleHoursPopup}
                                            >
                                                <span className="caret" />
                                            </button>
                                            <ul className={hoursPopup ? 'dropdown-menu show-block' : 'dropdown-menu'}>
                                                <li>
                                                    <a onClick={() => this.setHours(1, 'Every Hour')}>Every Hour</a>
                                                </li>
                                                <li>
                                                    <a onClick={() => this.setHours(3, 'Every 3rd Hour')}>Every 3rd Hour</a>
                                                </li>
                                                <li>
                                                    <a onClick={() => this.setHours(6, 'Every 6th Hour')}>Every 6th Hour</a>
                                                </li>
                                                <li role="separator" className="divider" />
                                                <li>
                                                    <a onClick={() => this.showHours(false)}>Hide All</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                )}
                {showHours && this.renderHours()}
            </div>
        );
    }

    private renderHours = () => {
        const { currentForecastHours, hoursFilter, showMore } = this.state;

        return (
            <div>
                <div className="row forecast-hours forecast-hours-title">
                    <div className="col-xs-2 no-padding">Hour</div>
                    <div className="col-xs-2 no-padding">Temp</div>
                    <div className="col-xs-3 no-padding">Wind</div>
                    <div className="col-xs-offset-3 col-xs-2 no-padding" />
                </div>
                {currentForecastHours.map((hour: any, index: number) => {
                    const show = hour.hour % hoursFilter === 0;

                    return (
                        <div key={index}>
                            {show && (
                                <div className="row forecast-hours forecast-hours-row">
                                    <div className="col-xs-2 no-padding">{hour.hourText}</div>
                                    <div className="col-xs-2 no-padding">{hour.temp}&#176;</div>
                                    <div className="col-xs-3 no-padding">
                                        {hour.windDir} {hour.windSpeed}
                                    </div>

                                    {hour.rain && (
                                        <div className="col-xs-3 no-padding">
                                            <img
                                                src="//res.cloudinary.com/weathertools/image/upload/c_lfill,w_20,h_20,o_90/site/light-rain.png"
                                                alt=""
                                            />
                                            {hour.QOP1Hr}&quot;
                                        </div>
                                    )}
                                    {!hour.rain && <div className="col-xs-3 no-padding" />}

                                    <div className="col-xs-2 no-padding text-info">
                                        <button
                                            type="button"
                                            className="btn btn-default btn-xs pull-right"
                                            onClick={() => this.showMore(hour.hour)}
                                        >
                                            {!showMore[hour.hour] && (
                                                <div>
                                                    More <span className="glyphicon glyphicon-chevron-down" />
                                                </div>
                                            )}
                                            {showMore[hour.hour] && (
                                                <div>
                                                    Less <span className="glyphicon glyphicon-chevron-up" />
                                                </div>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            )}
                            {showMore[hour.hour] && (
                                <div className="row forecast-hours-more">
                                    <div className="col-xs-12 no-right">
                                        <ul className="list-unstyled data-table">
                                            <li>
                                                <span className="data-label">Relative Humidity:</span>
                                                <span className="data-value">{hour.RH}%</span>
                                            </li>
                                            <li>
                                                <span className="data-label">Chance of PCPN:</span>
                                                <span className="data-value">{hour.pop1Hr}%</span>
                                            </li>
                                            <li>
                                                <span className="data-label">PCPN Amount:</span>
                                                <span className="data-value">{hour.QOP1Hr} IN</span>
                                            </li>
                                            <li>
                                                <span className="data-label">Chance of TSTM:</span>
                                                <span className="data-value">{hour.tstmPop}%</span>
                                            </li>
                                            <li>
                                                <span className="data-label">Dew Point:</span>
                                                <span className="data-value">{hour.dewPoint}&#176;</span>
                                            </li>
                                            <li>
                                                <span className="data-label">Chance of Fog:</span>
                                                <span className="data-value">{hour.fogPop}%</span>
                                            </li>
                                            <li>
                                                <span className="data-label">MSLP:</span>
                                                <span className="data-value">{hour.mslPres} MB</span>
                                            </li>
                                            <li>
                                                <span className="data-label">Sky Cover:</span>
                                                <span className="data-value">{hour.cloudCover}%</span>
                                            </li>
                                            <li>
                                                <span className="data-label">Visibility:</span>
                                                <span className="data-value">{hour.visibility} MI</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    private setCurrentForecastDay = (currentForecastDay: any) => {
        const { api, observation } = this.props;
        const { searchDate } = currentForecastDay;

        this.setState({ currentForecastDay, showHours: false }, () => {
            api.getForecastHours(observation.station._id, searchDate).then((currentForecastHours: any) => {
                this.setState({ currentForecastHours, showMore: {} });
            });
        });
    };

    private toggleHoursPopup = () => {
        this.setState({ hoursPopup: !this.state.hoursPopup });
    };

    private setHours = (hoursFilter: number, hoursText: string) => {
        this.setState({ hoursFilter, hoursText, hoursPopup: false, showHours: true });
    };

    private showHours = (showHours: boolean) => {
        this.setState({ showHours, hoursPopup: false });
    };

    private showMore = (hour: number) => {
        console.info(hour, this.state.showMore);

        this.setState({
            showMore: {
                ...this.state.showMore,
                [hour]: this.state.showMore[hour] ? !this.state.showMore[hour] : true,
            },
        });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        api: state.api.actions,
    };
};

export default connect(
    mapStoreToProps,
    null
)(Forecast);
