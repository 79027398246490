import { Store, createStore } from 'redux';
import { createAction, handleActions } from 'redux-actions';
import firebase from 'firebase';
import moment from 'moment';

export interface IMessages {
	name: string;
	email: string;
	message: string;
	viewed: boolean;
	type: 'contact';
	createdAt: string;
	updatedAt: string;
}

export interface IMessagesData {
	[key: string]: IMessages;
}

export default class Messages {
	public actions = {
		read: () => {
			const messagesRef = firebase.firestore().collection('messages');

			return messagesRef.get().then((messagesSnapshot) => {
				const messages: IMessagesData = {};

				messagesSnapshot.forEach((doc) => {
					const data: any = doc.data();

					messages[doc.id] = data;
				});

				this.store.dispatch(this.setMessages(messages));
			});
		},
		create: ({ name, email, message }: any) => {
			const data: IMessages = {
				name,
				email,
				message,
				viewed: false,
				type: 'contact',
				createdAt: moment().toISOString(),
				updatedAt: moment().toISOString(),
			};
			const messagesRef = firebase.firestore().collection('messages');

			return messagesRef.doc().set(data);
		},
		update: (messageId: string, data: any) => {
			const messagesRef = firebase
				.firestore()
				.collection('messages')
				.doc(messageId);

			return messagesRef.update(data).then(() => {
				return this.actions.read();
			});
		},
		delete: (messageId: string) => {
			const messagesRef = firebase
				.firestore()
				.collection('messages')
				.doc(messageId);

			return messagesRef.delete().then(() => {
				return this.actions.read();
			});
		},
	};

	public initialState = {
		actions: this.actions,
		data: {},
	};

	public reducer = handleActions<any>(
		{
			SET_MESSAGES: (state, action) => {
				return {
					...state,
					data: action.payload,
				};
			},
		},
		this.initialState
	);

	private setMessages = createAction('SET_MESSAGES');

	private store: Store = createStore(this.reducer);

	public setStore = (store: Store) => {
		this.store = store;
	};
}
