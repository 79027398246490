import { Store, createStore } from 'redux';
import { createAction, handleActions } from 'redux-actions';

import axios from 'axios';

export default class Admin {
	public actions = {
		init: () => {
			const readMessages = this.store.getState().messages.actions.read;

			return Promise.all([
				this.actions.getDailyReports(),
				this.actions.getApplications(),
				this.actions.getStations(),
				readMessages(),
			]).then(() => {
				this.store.dispatch(this.setInitted());
			});
		},
		getApplications: () =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'get',
						url: `/api/applications?access_token=${token}`,
					})
						.then((response) => {
							this.store.dispatch(this.setApplications(response.data));
							resolve();
						})
						.catch(() => reject());
				} else {
					reject();
				}
			}),
		getDailyReports: () =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'get',
						url: `/admin/api/uploads?tags=daily&access_token=${token}`,
					})
						.then((response) => {
							this.store.dispatch(this.setDailyReports(response.data));
							resolve();
						})
						.catch(() => {
							reject();
						});
				} else {
					reject();
				}
			}),
		getWiseconnFiles: () =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'get',
						url: `/admin/api/wiseconn/files?access_token=${token}`,
					})
						.then((response) => {
							this.store.dispatch(this.setWiseconnFiles(response.data));
							resolve();
						})
						.catch(() => {
							reject();
						});
				} else {
					reject();
				}
			}),
		getWiseconnFile: (key: string) =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'get',
						url: `/admin/api/wiseconn/file?access_token=${token}&key=${key}`,
					})
						.then((response) => {
							resolve(response);
						})
						.catch(() => {
							reject();
						});
				} else {
					reject();
				}
			}),
		deleteWiseconnFile: (key: string) =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'delete',
						url: `/admin/api/wiseconn/file?access_token=${token}&key=${key}`,
					})
						.then(() => {
							resolve();
						})
						.catch(() => {
							reject();
						});
				} else {
					reject();
				}
			}),
		postCurrentReport: (value: string) =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'post',
						url: `/admin/api/setting?access_token=${token}`,
						data: {
							key: 'summary',
							value,
						},
					})
						.then(() => {
							return this.actions.getDailyReports().then(() => resolve());
						})
						.catch(() => {
							reject();
						});
				} else {
					reject();
				}
			}),
		postDailyReport: (file: any, name: string) =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');
				const formData: any = new FormData();

				formData.append('file', file, name);
				formData.append('tag', 'daily');

				if (token) {
					axios({
						method: 'post',
						url: `/admin/api/upload/daily?access_token=${token}`,
						headers: {
							'Content-Type': 'multipart/form-data',
						},
						data: formData,
					})
						.then((results) => {
							const {
								cloudinary: { url },
							}: any = results.data;

							return this.actions.postCurrentReport(url).then(() => resolve());
						})
						.catch(() => {
							reject();
						});
				} else {
					reject();
				}
			}),
		postWiseconnReport: (file: any, name: string) =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');
				const formData: any = new FormData();

				formData.append('file', file, name);

				if (token) {
					axios({
						method: 'post',
						url: `/admin/api/upload/wiseconn?access_token=${token}`,
						headers: {
							'Content-Type': 'multipart/form-data',
						},
						data: formData,
					})
						.then(() => {
							resolve();
						})
						.catch(() => {
							reject();
						});
				} else {
					reject();
				}
			}),
		postApplication: (data: any) =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'post',
						url: `/admin/api/application?access_token=${token}`,
						data,
					})
						.then(() => this.actions.getApplications().then(() => resolve()))
						.catch(() => reject());
				} else {
					reject();
				}
			}),
		updateApplication: (applicationId: string, data: any) =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'put',
						url: `/admin/api/application/${applicationId}?access_token=${token}`,
						data,
					})
						.then(() => this.actions.getApplications().then(() => resolve()))
						.catch(() => reject());
				} else {
					reject();
				}
			}),
		deleteApplication: (applicationId: string) =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'delete',
						url: `/admin/api/application/${applicationId}?access_token=${token}`,
					})
						.then(() => this.actions.getApplications().then(() => resolve()))
						.catch(() => reject());
				} else {
					reject();
				}
			}),
		getObservationData: (params: any) =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'get',
						url: `/admin/api/data/observation?access_token=${token}`,
						params,
					})
						.then((results) => resolve(results.data))
						.catch(() => reject());
				} else {
					reject();
				}
			}),
		getStations: () =>
			new Promise((resolve, reject) => {
				const token = localStorage.getItem('token');

				if (token) {
					axios({
						method: 'get',
						url: `/api/stations?access_token=${token}`,
					})
						.then((response) => {
							this.store.dispatch(this.setStations(response.data));
							resolve();
						})
						.catch(() => reject());
				} else {
					reject();
				}
			}),
	};

	public initialState = {
		actions: this.actions,
		data: {
			dailyReports: null,
			applications: null,
			stations: null,
			wiseconnFiles: [],
		},
		initted: false,
	};

	public reducer = handleActions<any>(
		{
			SET_INITTED: (state) => {
				return {
					...state,
					initted: true,
				};
			},
			SET_DAILY_REPORTS: (state, action) => {
				return {
					...state,
					data: {
						...state.data,
						dailyReports: action.payload,
					},
				};
			},
			SET_APPLICATIONS: (state, action) => {
				return {
					...state,
					data: {
						...state.data,
						applications: action.payload,
					},
				};
			},
			SET_STATIONS: (state, action) => {
				return {
					...state,
					data: {
						...state.data,
						stations: action.payload,
					},
				};
			},
			SET_WISECONN_FILES: (state, action) => {
				return {
					...state,
					data: {
						...state.data,
						wiseconnFiles: action.payload,
					},
				};
			},
		},
		this.initialState
	);

	private setInitted = createAction('SET_INITTED');
	private setDailyReports = createAction('SET_DAILY_REPORTS');
	private setApplications = createAction('SET_APPLICATIONS');
	private setStations = createAction('SET_STATIONS');
	private setWiseconnFiles = createAction('SET_WISECONN_FILES');

	private store: Store = createStore(this.reducer);

	public setStore = (store: Store) => {
		this.store = store;
	};
}
