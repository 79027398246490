import React from 'react';
import {
    Button,
    Card,
    CircularProgress,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Grid,
    List,
    ListItem,
    ListSubheader,
    Theme,
    Typography,
    withStyles,
} from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Form from './Form';

const styles = (theme: Theme): Record<string, CSSProperties> => ({
    component: {
        position: 'relative',
        padding: 20,
        marginBottom: 50,
    },
    loading: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 500,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    heading: {
        marginBottom: theme.spacing(3),
    },
    subheader: {
        backgroundColor: '#fafafa',
        borderBottom: '1px solid rgba(0, 0, 0, .12)',
    },
    expansionPannel: {
        width: '100%',
    },
    listGroup: {
        borderWidth: '1px 1px 0 1px',
        borderColor: 'rgba(0, 0, 0, .12)',
        borderStyle: 'solid',
        marginBottom: 20,
    },
    listItem: {
        padding: 0,
    },
});

interface IProps {
    classes?: any;
    admin: any;
}

interface IState {
    loading: boolean;
    expandedId: string;
    showNew: boolean;
}

class Advisory extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false,
            expandedId: '',
            showNew: false,
        };
    }

    public render() {
        const { classes } = this.props;
        const { loading, showNew } = this.state;

        return (
            <Card square={true} className={classes.component}>
                {loading && (
                    <div className={classes.loading}>
                        <CircularProgress color="primary" />
                    </div>
                )}
                <Grid container={true} justify="space-between" className={classes.heading}>
                    <Typography variant="h6">Advisory</Typography>
                    <Button color="primary" onClick={this.toggleShowNew}>
                        {showNew ? 'Show Current Advisories' : 'Create New Advisory'}
                    </Button>
                </Grid>
                {showNew && <Form handleClose={this.toggleShowNew} />}
                {!showNew && <List>{this.renderFolders()}</List>}
            </Card>
        );
    }

    private renderFolders = () => {
        const {
            classes,
            admin: {
                applications: { folders },
            },
        } = this.props;

        return folders.map((folder: string, index: number) => {
            return (
                <div key={index} className={classes.listGroup}>
                    <ListSubheader className={classes.subheader}>{folder}</ListSubheader>
                    {this.renderFolderApplications(folder)}
                </div>
            );
        });
    };

    private renderFolderApplications = (folder: string) => {
        const {
            classes,
            admin: {
                applications: { applications },
            },
        } = this.props;
        const { expandedId } = this.state;

        return applications[folder].map((application: any, index: number) => {
            return (
                <ListItem key={index} divider={true} className={classes.listItem}>
                    <ExpansionPanel
                        expanded={expandedId === application._id}
                        square={true}
                        elevation={0}
                        className={classes.expansionPannel}
                        onChange={() => this.handleExpanded(application._id)}
                    >
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>{application.name}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {expandedId === application._id && (
                                <Form application={application} handleClose={() => this.handleExpanded(application._id)} />
                            )}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </ListItem>
            );
        });
    };

    private toggleShowNew = () => {
        this.setState({ showNew: !this.state.showNew });
    };

    private handleExpanded = (expandedId: string) => {
        this.setState({
            expandedId: this.state.expandedId === expandedId ? '' : expandedId,
        });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: state.admin.actions,
        admin: state.admin.data,
    };
};

export default compose<IProps, {}>(
    connect(
        mapStoreToProps,
        null
    ),
    withStyles(styles)
)(Advisory);
