import React from 'react';
import DailyReport from './DailyReport';
import WiseconnReport from './WiseconnReport';

class Dashboard extends React.Component {
    public render() {
        return (
            <React.Fragment>
                <DailyReport />
                <WiseconnReport />
            </React.Fragment>
        );
    }
}

export default Dashboard;
