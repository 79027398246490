import React from 'react';
import {
    Button,
    Card,
    FormLabel,
    Grid,
    MenuItem,
    TextField,
    Theme,
    Typography,
    withStyles,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Formik, Form, FormikProps } from 'formik';
import moment from 'moment';
import * as yup from 'yup';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { ExportToCsv } from 'export-to-csv';

const FormSchema = yup.object().shape({
    intervalLength: yup
        .number()
        .min(1)
        .required(),
    stationId: yup.string().required(),
});

const styles = (theme: Theme): Record<string, CSSProperties> => ({
    component: {
        position: 'relative',
        padding: 20,
        marginBottom: 50,
    },
    loading: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 500,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    heading: {
        marginBottom: theme.spacing(3),
    },
    subheader: {
        backgroundColor: '#fafafa',
        borderBottom: '1px solid rgba(0, 0, 0, .12)',
    },
    expansionPannel: {
        width: '100%',
    },
    listGroup: {
        borderWidth: '1px 1px 0 1px',
        borderColor: 'rgba(0, 0, 0, .12)',
        borderStyle: 'solid',
        marginBottom: 20,
    },
    listItem: {
        padding: 0,
    },
    formLabel: {
        marginRight: 8,
        fontSize: 16,
        width: 100,
    },
    dense: {
        marginTop: theme.spacing(2),
    },
});

interface IProps {
    classes?: any;
    actions: any;
    admin: any;
}

interface IState {
    results: any[];
}

class Data extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            results: [],
        };
    }

    public render() {
        const { classes } = this.props;

        return (
            <Card square={true} className={classes.component}>
                <Grid container={true} justify="space-between" className={classes.heading}>
                    <Typography variant="h6">Observation Data</Typography>
                </Grid>
                {this.renderForm()}
                {this.renderResults()}
            </Card>
        );
    }

    private renderForm = () => {
        const { classes, admin } = this.props;
        const initialValues = {
            action: '',
            dataType: 'eto',
            stationId: 'F75',
            intervalType: 'day',
            intervalLength: 1,
        };

        return (
            <Formik
                initialValues={initialValues}
                onSubmit={this.handleGetData}
                validationSchema={FormSchema}
                render={({ values, errors, touched, handleChange, setFieldValue, isSubmitting, isValid }: FormikProps<any>) => (
                    <Form noValidate={true}>
                        <Grid container={true}>
                            <Grid container={true} item={true} xs={12} style={{ marginBottom: 10 }}>
                                <TextField
                                    name="dataType"
                                    variant="outlined"
                                    select={true}
                                    value={values.dataType}
                                    onChange={handleChange}
                                    margin="dense"
                                    label="Data"
                                >
                                    <MenuItem value="eto">ETo Only</MenuItem>
                                    <MenuItem value="all">All</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid container={true}>
                            <Grid container={true} item={true} xs={12} style={{ marginBottom: 10 }}>
                                <TextField
                                    name="stationId"
                                    variant="outlined"
                                    select={true}
                                    value={values.stationId}
                                    onChange={handleChange}
                                    margin="dense"
                                    error={!!(touched && errors && errors.stationId)}
                                    label="Station"
                                >
                                    {admin.stations
                                        .filter((station: any) => {
                                            if (values.dataType === 'eto') {
                                                return ['BRW', 'BWD', 'WRD', 'HWS'].includes(station.name);
                                            }
                                            return true;
                                        })
                                        .map((station: any, index: number) => (
                                            <MenuItem key={index} value={station.stationId}>
                                                {station.name}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </Grid>
                        </Grid>

                        <Grid container={true}>
                            <Grid container={true} item={true} xs={12} style={{ marginBottom: 10 }}>
                                <TextField
                                    name="intervalLength"
                                    value={values.intervalLength}
                                    onChange={handleChange}
                                    margin="dense"
                                    error={!!(errors && errors.intervalLength)}
                                    style={{ width: 50, marginRight: 8 }}
                                    label="Get Last"
                                />
                                <TextField
                                    name="intervalType"
                                    variant="outlined"
                                    select={true}
                                    value={values.intervalType}
                                    onChange={handleChange}
                                    margin="dense"
                                >
                                    <MenuItem value="day">{`Day${values.intervalLength > 1 ? 's' : ''}`}</MenuItem>
                                    <MenuItem value="hour">{`Hour${values.intervalLength > 1 ? 's' : ''}`}</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid container={true} style={{ paddingTop: 24 }}>
                            <Button
                                type="submit"
                                disabled={isSubmitting && values.action === 'view'}
                                variant="contained"
                                color="primary"
                                onClick={() => setFieldValue('action', 'view')}
                            >
                                View
                            </Button>
                            <Button
                                type="submit"
                                disabled={isSubmitting && values.action === 'download'}
                                variant="contained"
                                color="primary"
                                onClick={() => setFieldValue('action', 'download')}
                                style={{ marginLeft: 8 }}
                            >
                                Download
                            </Button>
                        </Grid>
                    </Form>
                )}
            />
        );
    };

    private renderResults = () => {
        const { results } = this.state;

        if (results.length > 0) {
            return (
                <div style={{ marginTop: 32, overflowX: 'scroll' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {Object.keys(results[0]).map((key, index) => {
                                    return <TableCell key={index}>{key}</TableCell>;
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {results.map((row, rowIndex) => {
                                return (
                                    <TableRow key={rowIndex}>
                                        {Object.keys(row).map((key, cellIndex) => {
                                            return <TableCell key={cellIndex}>{row[key]}</TableCell>;
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            );
        }
    };

    private handleGetData = (values: any, { setSubmitting }: any) => {
        const { actions } = this.props;

        actions.getObservationData(values).then((data: any) => {
            const results: any = [];

            data.forEach((row: any) => {
                const record: any = {};
                const baseDate = moment(row.timestamp, 'YYYY-MM-DDThh:mm:ssZ');

                record.Id = row.stationId;
                record.Date = baseDate.format();
                record.Year = baseDate.format('YYYY');
                record.Julian = baseDate.format('DDD');
                record.Time = baseDate.format('HH:mm');

                if (values.dataType === 'eto') {
                    if (values.intervalType === 'day') {
                        record.ETo = row.values.ETo;
                    } else {
                        record.EtoHr = row.values.EToHr;
                    }
                } else {
                    Object.keys(row.values).forEach((key) => {
                        record[key] = row.values[key];
                    });
                }

                results.push(record);
            });

            this.setState({ results: values.action === 'download' ? [] : results }, () => {
                const headers = results.length > 0 ? Object.keys(results[0]) : [''];
                let csvExporter;

                if (values.action === 'download') {
                    csvExporter = new ExportToCsv({
                        filename: `${values.stationId}-${values.intervalType}-${values.intervalLength}-${values.dataType}`,
                        fieldSeparator: ',',
                        quoteStrings: '"',
                        decimalSeparator: '.',
                        showLabels: true,
                        useTextFile: false,
                        headers,
                    });

                    csvExporter.generateCsv(results);
                }

                setSubmitting(false);
            });
        });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: state.admin.actions,
        admin: state.admin.data,
    };
};

export default compose<IProps, {}>(
    connect(
        mapStoreToProps,
        null
    ),
    withStyles(styles)
)(Data);
