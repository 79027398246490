import React from 'react';
import Tabs from '../Tabs';
import { connect } from 'react-redux';
import Forecast from './Forecast';
import moment from 'moment';

interface IProps {
    api?: any;
    observation: any;
}

interface IState {
    initted: boolean;
    tab: string;
}

class Observation extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            initted: false,
            tab: 'today',
        };
    }

    public render() {
        const { observation } = this.props;
        const { tab } = this.state;

        return (
            <div className="station-item">
                <div style={{ display: 'none' }}>{observation.station.stationId}</div>
                <h4>{observation.station.description}</h4>
                <h6>As of: {observation.asOf}</h6>
                <div className="station-item-tabs">
                    <ul className="nav nav-tabs">
                        <li className={tab === 'today' ? 'active' : ''}>
                            <a onClick={() => this.onChangeTab('today')}>Today</a>
                        </li>
                        <li className={tab === 'yesterday' ? 'active' : ''}>
                            <a onClick={() => this.onChangeTab('yesterday')}>Yesterday</a>
                        </li>
                        {observation.hasForecasts && (
                            <li className={tab === 'forecast' ? 'active' : ''}>
                                <a onClick={() => this.onChangeTab('forecast')}>Forecast</a>
                            </li>
                        )}
                    </ul>
                </div>
                {tab === 'today' && this.renderToday()}
                {tab === 'yesterday' && this.renderYesterday()}
                {tab === 'forecast' && <Forecast observation={observation} />}
            </div>
        );
    }

    private renderToday = () => {
        const { observation } = this.props;

        return (
            <div className="section today">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="media">
                            <div className="media-body">
                                <span className="today-temp">
                                    {observation.min15.avgTemp}
                                    <span className="today-degree">&#8457;</span>
                                </span>
                                <div className="today-high-low">
                                    <span className="high">{observation.min15.runningMaxTemp}&#176;</span>
                                    <span className="low">{observation.min15.runningMinTemp}&#176;</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-6">
                        <ul className="list-unstyled data-table">
                            <li>
                                <span className="data-label">15min PCPN:</span>
                                <span className="data-value">{observation.min15['15minPcpn']} IN</span>
                            </li>
                            <li>
                                <span className="data-label">Since 12am:</span>
                                <span className="data-value">{observation.min15.runningTotalPcpn} IN</span>
                            </li>
                            <li>
                                <span className="data-label">Humidity:</span>
                                <span className="data-value">{observation.min15.avgRH}%</span>
                            </li>
                        </ul>
                    </div>
                    <div className="col-xs-6">
                        <ul className="list-unstyled data-table">
                            <li>
                                <span className="data-label">Dew Point:</span>
                                <span className="data-value">{observation.min15.avgDewPointTemp}&#176;</span>
                            </li>
                            <li>
                                <span className="data-label">Wind:</span>
                                <span className="data-value">
                                    {observation.min15.avgWindDir} {observation.min15.avgWindSpeed} MPH
                                </span>
                            </li>
                            <li>
                                <span className="data-label">Gust:</span>
                                <span className="data-value">{observation.min15.maxWindGust} MPH</span>
                            </li>
                            {observation.hour.hourETo > -100 && (
                                <li>
                                    <span className="data-label">Hourly ETo:</span>
                                    <span className="data-value">{observation.hour.hourETo} IN</span>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        );
    };

    private renderYesterday = () => {
        const { observation } = this.props;

        return (
            <div className="section yesterday">
                <div className="row">
                    <div className="col-xs-6 no-right">
                        <ul className="list-unstyled data-table">
                            <li>
                                <span className="data-label">Max Temp:</span>
                                <span className="data-value">{observation.day.maxTemp}&#176;</span>
                            </li>
                            <li>
                                <span className="data-label">Min Temp:</span>
                                <span className="data-value">{observation.day.minTemp}&#176;</span>
                            </li>
                            <li>
                                <span className="data-label">24hr PCPN:</span>
                                <span className="data-value">{observation.day.totalPcpn} IN</span>
                            </li>
                            <li>
                                <span className="data-label">GDD50:</span>
                                <span className="data-value">{observation.day.growingDegreeDaysSimple}&#176;</span>
                            </li>
                        </ul>
                    </div>
                    <div className="col-xs-6 no-left">
                        <ul className="list-unstyled data-table">
                            <li>
                                <span className="data-label">Time of Max:</span>
                                <span className="data-value">{moment(observation.day.timeMaxTemp, 'hh:mm:ss').format('h:mm A')}</span>
                            </li>
                            <li>
                                <span className="data-label">Time of Min:</span>
                                <span className="data-value">{moment(observation.day.timeMinTemp, 'hh:mm:ss').format('h:mm A')}</span>
                            </li>
                            {observation.day.totalETo > -100 && (
                                <li>
                                    <span className="data-label">24hr ETo:</span>
                                    <span className="data-value">{observation.day.totalETo} IN</span>
                                </li>
                            )}
                            <li>
                                <span className="data-label">YTD PCPN:</span>
                                <span className="data-value">{observation.day.seasonalYearlyPcpn} IN</span>
                            </li>
                            <li>
                                <span>(July 1 - June 30)</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    };

    private onChangeTab = (tab: string) => {
        this.setState({ tab });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        api: state.api.actions,
    };
};

export default connect(mapStoreToProps, null)(Observation);
