import React from 'react';
import { connect } from 'react-redux';
import Tabs from '../Tabs';
import moment from 'moment';

interface IProps {
    api: any;
}

interface IState {
    initted: boolean;
    loading: boolean;
    tab: string;
    applications: { [key: string]: any };
    folders: any[];
    selectedApplication: any;
    selectedFolder: any;
    showApplications: boolean;
    showFolders: boolean;
    advisories: any[];
    showDetails: boolean;
    snapshots: any[];
    archiveSuccess: boolean;
    archiveError: boolean;
}

class Advisory extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            initted: false,
            loading: false,
            tab: 'conditions',
            applications: {},
            folders: [],
            selectedApplication: null,
            selectedFolder: null,
            showApplications: false,
            showFolders: false,
            advisories: [],
            showDetails: false,
            snapshots: [],
            archiveSuccess: false,
            archiveError: false,
        };
    }

    public componentDidMount() {
        const { api } = this.props;

        Promise.all([api.getApplications(), api.getAdvisorySnapshots()]).then((results: any) => {
            const { applications, folders } = results[0];
            const snapshots = results[1];
            const selectedApplication = applications[Object.keys(applications)[0]][0];
            const selectedFolder = folders[0];

            this.setState({ applications, folders, selectedApplication, selectedFolder, snapshots, initted: true });
        });
    }

    public render() {
        return (
            <div className="container app">
                <Tabs tab="advisory" />
                <div className="row app-view">{this.renderContent()}</div>
            </div>
        );
    }

    private renderContent = () => {
        const {
            initted,
            loading,
            advisories,
            applications,
            folders,
            selectedApplication,
            selectedFolder,
            showApplications,
            showFolders,
            tab,
            showDetails,
            snapshots,
            archiveError,
            archiveSuccess,
        } = this.state;
        const token = localStorage.getItem('token');

        if (!initted) {
            return <div className="loading" />;
        }

        return (
            <div className="col-xs-12 col-md-8 col-md-offset-2">
                {loading && <div className="loading" />}
                <div className="tab">
                    <div className="alerts-heading">
                        <h4>Herbicide Stewardship (per Manufacturer’s Label, see below)</h4>
                        <h5>
                            Always consult the{' '}
                            <a href="http://www.cdpr.ca.gov/docs/county/comenu.htm" target="_blank">
                                County Ag Commissioner’s Office
                            </a>{' '}
                            for additional local regulations.
                        </h5>
                        <p>
                            The following data (contingency table below) are for planning purposes only and are to be used at your own risk.
                            Forecast data is highly-provisional in nature and should be used accordingly. After selecting your spray
                            application and pressing “Calculate”, click “Show Details” to reveal all weather criteria exceedances. Very low
                            wind speeds and relative humidity are, in most cases, marked as “Questionable” or “Unfavorable” Conditions due
                            to their negative impact on dispersion, droplet integrity and droplet size (see Manufacturer’s Label below).
                            Proximity to sensitive crops takes precedence over any information provided herein.
                        </p>
                        <p>
                            Always be sure to consult a site’s real-time observation before commencing a job as the grower and applicator
                            share responsibility for the application.
                        </p>
                    </div>
                    <ul className="nav nav-tabs">
                        <li role="presentation" className={tab === 'conditions' ? 'active' : ''} onClick={() => this.setTab('conditions')}>
                            <a>Conditions</a>
                        </li>
                        <li role="presentation" className={tab === 'snapshots' ? 'active' : ''} onClick={() => this.setTab('snapshots')}>
                            <a>Snapshots</a>
                        </li>
                    </ul>
                    {tab === 'conditions' && (
                        <div>
                            <div className="form-inline advisory">
                                <div className="form-group">
                                    <label>Spray Conditions for:</label>
                                </div>
                                <div className="form-group">
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-default dropdown-toggle" onClick={this.toggleFolders}>
                                            {selectedFolder ? selectedFolder : ''} <span className="caret" />
                                        </button>
                                        <ul className={`dropdown-menu ${showFolders ? 'show-block' : ''}`}>
                                            {folders.map((folder, index) => {
                                                return (
                                                    <li key={index} onClick={() => this.setFolder(folder)}>
                                                        <a>{folder}</a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="btn-group">
                                        <button type="button" className="btn btn-default dropdown-toggle" onClick={this.toggleApplications}>
                                            {selectedApplication ? selectedApplication.name : ''} <span className="caret" />
                                        </button>
                                        <ul className={`dropdown-menu ${showApplications ? 'show-block' : ''}`}>
                                            {applications[selectedFolder].map((application: any, index: number) => {
                                                return (
                                                    <li key={index} onClick={() => this.setApplication(application)}>
                                                        <a>{application.name}</a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-warning" onClick={this.getAdvisories}>
                                        Calculate
                                    </button>
                                </div>
                            </div>
                            {selectedApplication && selectedApplication.specsheet && (
                                <div>
                                    <h5>
                                        <a href="{{selectedApplication.specsheet}}" target="_blank">
                                            Manufacturer’s Label
                                        </a>
                                    </h5>
                                </div>
                            )}
                            <table className="table advisory-key">
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="advisory-status advisory-status-green" />
                                        </td>
                                        <td> = Favorable Conditions</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="advisory-status advisory-status-yellow" />
                                        </td>
                                        <td> = Questionable Conditions</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="advisory-status advisory-status-red" />
                                        </td>
                                        <td> = Unfavorable Conditions</td>
                                    </tr>
                                </tbody>
                            </table>
                            {selectedApplication && (
                                <div>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-6">
                                            <ul className="list-group">
                                                <li className="list-group-item list-group-item-warning">Yellow Criterion</li>
                                                {selectedApplication.yellowCriterion.map((criterion: any, index: number) => {
                                                    return (
                                                        <li key={index} className="list-group-item">
                                                            {`${criterion.criteria.text} ${criterion.threshold} ${criterion.criteria.unit}`}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                        <div className="col-xs-12 col-sm-6">
                                            <ul className="list-group">
                                                <li className="list-group-item list-group-item-danger">Red Criterion</li>
                                                {selectedApplication.redCriterion.map((criterion: any, index: number) => {
                                                    return (
                                                        <li key={index} className="list-group-item">
                                                            {`${criterion.criteria.text} ${criterion.threshold} ${criterion.criteria.unit}`}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="btn-group" style={{ marginBottom: 10 }}>
                                <button className="btn btn-warning" disabled={advisories.length < 1} onClick={this.toggleDetails}>
                                    {!showDetails && (
                                        <div>
                                            Show Details <span className="glyphicon glyphicon-chevron-down" />
                                        </div>
                                    )}
                                    {showDetails && (
                                        <div>
                                            Hide Details <span className="glyphicon glyphicon-chevron-up" />
                                        </div>
                                    )}
                                </button>
                                <button className="btn btn-default" disabled={advisories.length < 1} onClick={this.saveAdvisorySnapshot}>
                                    {archiveSuccess && (
                                        <span className="text-success">
                                            Snapshot Saved
                                            <span className="glyphicon glyphicon-ok" style={{ paddingRight: 4 }} aria-hidden="true" />
                                        </span>
                                    )}
                                    {archiveError && (
                                        <span className="text-danger">
                                            Error Saving
                                            <span className="glyphicon glyphicon-alert" style={{ paddingRight: 4 }} aria-hidden="true" />
                                        </span>
                                    )}
                                    {!archiveSuccess && !archiveError && <span>Save Snapshot</span>}
                                </button>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-bordered table-condensed">
                                    <thead>
                                        <tr>
                                            <td rowSpan={2}>Site</td>
                                            <td colSpan={2} rowSpan={2}>
                                                As of:
                                            </td>
                                            <td colSpan={7}>Future Periods</td>
                                        </tr>
                                        <tr>
                                            <td>+ 2Hr</td>
                                            <td>+ 4Hr</td>
                                            <td>+ 6Hr</td>
                                            <td>+ 12Hr</td>
                                            <td>+ 24Hr</td>
                                            <td>+ 36Hr</td>
                                            <td>+ 48Hr</td>
                                        </tr>
                                    </thead>
                                    <tbody>{this.renderAdvisories()}</tbody>
                                </table>
                            </div>
                        </div>
                    )}
                    {tab === 'snapshots' && (
                        <div style={{ paddingTop: 20 }}>
                            <table className="table table-condensed">
                                <thead>
                                    <tr>
                                        <td>Application</td>
                                        <td>Saved On</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {snapshots.map((snapshot, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{snapshot.metadata.selectedApplication}</td>
                                                <td>
                                                    {moment(snapshot.uploadDate).format('MMM D, YYYY, h:mm:ss A')}

                                                    <div className="btn-group pull-right">
                                                        <a
                                                            type="button"
                                                            className="btn btn-default btn-xs"
                                                            href={`/api/advisory/snapshot/${snapshot._id}?access_token=${token}`}
                                                            download={`snapshot`}
                                                        >
                                                            <span
                                                                className="glyphicon glyphicon-download-alt"
                                                                style={{ paddingRight: 4 }}
                                                            />
                                                            Download
                                                        </a>
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger btn-xs"
                                                            onClick={() => this.deleteAdvisorySnapshot(snapshot._id)}
                                                        >
                                                            Delete
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    private renderAdvisories = () => {
        const { advisories } = this.state;

        return advisories.map((advisory: any, index: number) => {
            return (
                <tr key={index}>
                    <td>{advisory.station.name}</td>
                    <td style={{ minWidth: 140 }}>{advisory.asOf}</td>
                    {this.renderAdvisoryStatus(advisory)}
                </tr>
            );
        });
    };

    private renderAdvisoryStatus = (advisory: any) => {
        const { showDetails } = this.state;

        return Object.keys(advisory.status).map((key: string, index: number) => {
            const status = advisory.status[key];

            if (!showDetails) {
                return (
                    <td key={index}>
                        <div className={`advisory-status advisory-status-${status.color}`} />
                    </td>
                );
            }

            return (
                <td key={index} className={`advisory-details advisory-details-${status.color}`}>
                    <div>
                        <ul className="list-unstyled">
                            {status.details &&
                                status.details.map((detail: any, detailIndex: number) => {
                                    return <li key={detailIndex}>{detail}</li>;
                                })}
                        </ul>
                    </div>
                </td>
            );
        });
    };

    private setTab = (tab: string) => {
        this.setState({ tab });
    };

    private toggleApplications = () => {
        this.setState({ showApplications: !this.state.showApplications });
    };

    private setApplication = (selectedApplication: any) => {
        this.setState({ advisories: [], showApplications: false, selectedApplication });
    };

    private toggleFolders = () => {
        this.setState({ showFolders: !this.state.showFolders });
    };

    private setFolder = (selectedFolder: any) => {
        const { applications } = this.state;
        const selectedApplication = applications[selectedFolder][0];

        this.setState({ advisories: [], showFolders: false, selectedApplication, selectedFolder });
    };

    private toggleDetails = () => {
        this.setState({ showDetails: !this.state.showDetails });
    };

    private getAdvisories = () => {
        const { api } = this.props;
        const { selectedApplication } = this.state;
        const advisoryId = selectedApplication._id;

        this.setState({ loading: true }, () => {
            api.getAdvisories(advisoryId).then((advisories: any) => {
                this.setState({ advisories, loading: false });
            });
        });
    };

    private deleteAdvisorySnapshot = (snapshotId: string) => {
        const { api } = this.props;

        api.deleteAdvisorySnapshot(snapshotId).then((snapshots: any) => {
            this.setState({ snapshots });
        });
    };

    private saveAdvisorySnapshot = () => {
        const { api } = this.props;
        const { advisories, selectedApplication } = this.state;

        api.postAdvisorySnapshot({ advisories, selectedApplication })
            .then((snapshots: any) => {
                this.setState({ snapshots, archiveSuccess: true }, () => {
                    setTimeout(() => {
                        this.setState({ archiveSuccess: false });
                    }, 5000);
                });
            })
            .catch(() => {
                this.setState({ archiveError: true }, () => {
                    setTimeout(() => {
                        this.setState({ archiveError: false });
                    }, 5000);
                });
            });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        api: state.api.actions,
    };
};

export default connect(
    mapStoreToProps,
    null
)(Advisory);
