import React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
    tab: string;
}

class Tabs extends React.Component<IProps> {
    public render() {
        const { tab } = this.props;

        return (
            <div className="navbar navbar-default navbar-fixed-top app-tabs">
                <div className="col-xs-12 col-md-8 col-md-offset-2">
                    <div className="app-tab-wrapper">
                        <ul className="nav nav-tabs">
                            <li role="presentation">
                                <Link to="/app/sites" className={tab === 'sites' ? 'router-link-active' : ''}>
                                    Sites
                                </Link>
                            </li>
                            <li role="presentation">
                                <Link to="/app/alerts" className={tab === 'alerts' ? 'router-link-active' : ''}>
                                    Alerts
                                </Link>
                            </li>
                            <li role="presentation">
                                <Link to="/app/advisory" className={tab === 'advisory' ? 'router-link-active' : ''}>
                                    Advisory
                                </Link>
                            </li>
                            <li role="presentation">
                                <Link to="/app/settings" className={tab === 'settings' ? 'router-link-active' : ''}>
                                    Settings
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default Tabs;
